import React, { useContext, useState } from 'react';

import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import { isIOS, isSafari, isDesktop, isMacOs, isAndroid } from 'react-device-detect';

import { BookingContext } from '../../context/booking';
import { wwwDir } from '../../utils/cordovaGlobals';
import BoardingPassCardListWallet from './BoardingPassCardListWallet/BoardingPassCardListWallet';
import Applewallet from '../../assets/img/applewallet.png';
import Googlewallet from '../../assets/img/googlewallet.png';
import Text from '../UI/Text/Text';
import Dialog from '../UI/Dialog/Dialog';

const BoardingPassWallet = () => {
  const {
    booking,
  } = useContext(BookingContext);

  const [open, setOpen] = React.useState(false);
  const [walletType, setWalletType] = useState(null);

  const dialog = (
    <Dialog open={open} setOpen={setOpen} title="" action={null}>
      <>
        <Typography variant="h3">{`Download to ${walletType} Wallet`}</Typography>

        {// eslint-disable-next-line max-len
            (booking && booking.allJourneys && booking.allJourneys[0][0].passengers.map((pax) => booking.allJourneys.map((flights) => <BoardingPassCardListWallet pax={pax} flights={flights} walletType={walletType} />)))
          }

        <Box mt={4}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={(() => setOpen(false))}
          >
            Close
          </Button>
        </Box>
      </>
    </Dialog>
  );

  return (
    <Box my={5}>
      {dialog}
      <Typography variant="h2"><Text id="boarding-pass-title-apple" /></Typography>
      <Typography><Text id="boarding-pass-text-apple-google" /></Typography>
      <ButtonBase
        fullWidth
        color="secondary"
        variant="contained"
        onClick={(() => {
          setWalletType('Apple');
          setOpen(true);
        })}
      >
        <img src={`${wwwDir}${Applewallet}`} alt="logo" />
      </ButtonBase>
      <ButtonBase
        fullWidth
        color="secondary"
        variant="contained"
        onClick={(() => {
          setWalletType('Google');
          setOpen(true);
        })}
      >
        <img src={`${wwwDir}${Googlewallet}`} alt="logo" />
      </ButtonBase>
    </Box>
  );
};

export default BoardingPassWallet;
