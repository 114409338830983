/* eslint-disable max-len */
import React, { useState } from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { Typography, AccordionDetails } from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { Add, Minimize } from '@material-ui/icons';

const AccordionSummary = withStyles({
  expandIcon: {
    transform: 'none !important',
  },
})(MuiAccordionSummary);

const useStyles = makeStyles(() => ({
  accordion: {
    marginBottom: '10px',
    boxShadow: '0 0px 24px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    '&::before': {
      height: '0px',
    },
  },
}));

const Accordion = (props) => {
  const {
    children, title,
  } = props;
  const classes = useStyles(props);
  const [expanded, setExpanded] = useState(false);

  return (
    <MuiAccordion expanded={expanded} onClick={(() => setExpanded(!expanded))} className={clsx(classes.accordion)}>
      <AccordionSummary expandIcon={expanded ? <Minimize /> : <Add />}>
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
