/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Text from '../../../UI/Text/Text';

const useStyles = makeStyles((theme) => ({
  rectangle: {
    border: 'solid 2px #369e3b',
    padding: 5,
    paddingLeft: 10,
    color: '#000',
    paddingRight: 10,
    textAlign: 'center',
    width: 'inherit',
  },
  active: {
    background: theme.palette.secondary.main,
    color: '#fff',
  },
}));

const PassengerCard = (props) => {
  const classes = useStyles();

  const {
    activeFlightInJourneyIndex,
    activeJourney,
    activePassenger,
    setActivePassenger,
    newSeat = [],
  } = props;

  const passengerList = activeJourney[activeFlightInJourneyIndex].passengers.sort((a, b) => (a.type > b.type) - (a.type < b.type));
  return (
    <Box px={3} py={{ xs: 2, sm: 3 }} mr={{ xs: 0, md: 2 }} style={{ background: '#fff' }}>
      <Typography variant="h2"><Text id="seat-map-passengers-title" /></Typography>
      {passengerList.map((pax, i) => (
        (pax.type !== 'IN') && (
        <Box key={pax.passengerIdentifier} mb={1}>
          <Grid container key={pax.passengerIdentifier}>
            <Grid item xs={9}>
              <Box
                mx={1}
                className={
                            clsx({
                              [classes.active]: (activePassenger === i),
                              [classes.rectangle]: true,
                            })
                          }
                onClick={(() => setActivePassenger(i))}
              >
                <Typography>
                  {pax.name.firstName}
                  {' '}
                  {pax.name.surName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100%"
                className={
                            clsx({
                              [classes.active]: (activePassenger === i),
                              [classes.rectangle]: true,
                            })
                          }
                onClick={(() => setActivePassenger(i))}
              >
                <Typography>
                  {newSeat[i] ? newSeat[i] : pax.seat}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        )))}
    </Box>
  );
};

PassengerCard.propTypes = {
  activeFlightInJourneyIndex: PropTypes.number,
  activeJourney: PropTypes.array,
  activePassenger: PropTypes.number,
  setActivePassenger: PropTypes.func,
  newSeat: PropTypes.array,
};

export default PassengerCard;
