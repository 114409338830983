import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';

import { BookingContext } from '../../context/booking';
import { BoardingPassContext } from '../../context/boardingPass';
import BoardingPassCardList from './BoardingPassCardList/BoardingPassCardList';
import Dialog from '../UI/Dialog/Dialog';
import Text from '../UI/Text/Text';
import Loading from '../UI/Loading/Loading';

const BoardingPass = (props) => {
  const {
    open,
    setOpen,
  } = props;

  const {
    booking,
  } = useContext(BookingContext);

  const {
    fetchBoardingPassMail,
  } = useContext(BoardingPassContext);

  const [loading, setLoading] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState(-1);
  const [email, setEmail] = useState('');

  const toggle = (id) => {
    if (id === selectedJourney) {
      setSelectedJourney(-1);
    } else setSelectedJourney(id);
  };

  const getFlight = (passenger, journeyIndex) => {
    let lastPassengerFlightIdentifier;
    const flights = [];
    booking.allJourneys[journeyIndex].map((flight) => {
      if (flight.status !== 'Departed') {
        const foundPassenger = flight.passengers.find((p) => (
          p.passengerIdentifier === passenger.passengerIdentifier
        ));

        if (foundPassenger && foundPassenger.checkin
        && flight.flightTime.scheduledDeparture
        && foundPassenger.passengerFlightIdentifier !== lastPassengerFlightIdentifier) {
          lastPassengerFlightIdentifier = foundPassenger.passengerFlightIdentifier;
          flights.push({
            departureDate: flight.flightTime.scheduledDeparture,
            flightNumber: flight.flightNr,
            fromStationCode: flight.station.from.code,
            marketingCarrier: flight.carrier,
            passengerFlightIdentifier: foundPassenger.passengerFlightIdentifier,
          });
        }
      }
      return null;
    });
    return flights;
  };

  const request = (journeyIndex) => booking && {
    customers: booking && booking.allJourneys[journeyIndex][0].passengers
      .filter((pax) => pax.checkin)
      .map((passenger) => ({
        flights: getFlight(passenger, journeyIndex),
        surname: passenger.name.surName,
      })),
    pnr: booking.allJourneys[journeyIndex][0].passengers[0].pnr,
    station: booking.allJourneys[journeyIndex][0].station.from.code,
    email,
  };

  const action = (
    <>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button
            fullWidth
            variant="outlined"
            color="secondary"
            onClick={(() => setOpen(false))}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Button
            disabled={selectedJourney === -1 || !email.includes('@') || !email.includes('.')}
            fullWidth
            color="secondary"
            variant="contained"
            onClick={(() => {
              setLoading(true);
              fetchBoardingPassMail(request(selectedJourney)).then(() => {
                setLoading(false);
                setOpen();
              });
            })}
          >
            <Text id="footer-continue-button" />
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Dialog open={open} setOpen={setOpen} title="" action={null}>
      {!loading ? (
        <>
          <Typography variant="h3">Send to email</Typography>
          <Typography><Text id="boarding-pass-Dialog-email-subTitle" /></Typography>
          {booking && booking.allJourneys && (booking.allJourneys.length > 0) && (
            booking.allJourneys.map((journey, i) => (
              <BoardingPassCardList
                journeyIndex={i}
                selectedJourney={selectedJourney}
                key={journey[0].flightNr + journey[0].station.from.code}
                flights={journey}
                toggle={toggle}
              />
            ))
          )}
          <Box mt={4}>
            <Typography><Text id="boarding-pass-Dialog-email-titleForm" /></Typography>
            <Box mt={0.5}>
              <TextField
                fullWidth
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                variant="outlined"
                id="textfield-pnr"
                label={<Text id="boarding-pass-Dialog-email-textForm" />}
              />
            </Box>
          </Box>
          <Box mt={4}>
            {action}
          </Box>
        </>
      ) : (
        <DialogContent>
          <Box mx="auto" height="350px" maxWidth="350px" textAlign="center" style={{ background: '#fff' }}>
            <Typography variant="h3">
              Sending boarding pass to
              {` ${email} `}
              for passenger
              {booking.primeJourney[0].passengers.length > 1 ? 's' : ''}
            </Typography>
            {booking.primeJourney[0].passengers.map((pax) => (
              <Typography variant="h5">
                {pax.checkin && pax.name.fullName}
              </Typography>
            ))}
            <Box my={5}>
              <Loading noMargin />
            </Box>

          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

BoardingPass.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default BoardingPass;
