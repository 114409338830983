import React from 'react';
import { makeStyles, AppBar } from '@material-ui/core';
import TermsConditionsLink from './TermsConditionsLink';

const useStyles = makeStyles((theme) => ({
  footer: {
    alignItems: 'center',
    textAlign: 'center',
  },
  link: {
    color: '#CDD0D6',
    textDecoration: 'none',
  },
  appBar: {
    top: 'auto',
    bottom: '0',
    placeContent: 'center',
    backgroundColor: '#fff',
    marginTop: '3px',
    padding: '2px',
    minHeight: '50px',
    textAlign: 'right',
    justifyContent: 'flex-end',
    zIndex: 50,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
}));

const CommonFooter = () => {
  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <TermsConditionsLink />
    </AppBar>
  );
};

export default CommonFooter;
