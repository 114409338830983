/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useState, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import CancelCheckinIcon from '@material-ui/icons/Clear';
import WarningIcon from '@material-ui/icons/Warning';
import SeatmapIcon from '@material-ui/icons/EventSeat';

import { SeatMapContext } from '../../../../../context/seatMap';
import { CheckinContext } from '../../../../../context/checkin';
import { BookingContext } from '../../../../../context/booking';
import { ThemeContext } from '../../../../../context/theme';

import Text from '../../../../UI/Text/Text';
import AcceptanceModal from '../../../Acceptance/AcceptanceModal/AcceptanceModal';

const useStyles = makeStyles((theme) => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    color: theme.palette.warning.main,
  },
  warningIcon: {
    height: 18,
    marginRight: 3,
  },
  actionCheckInButtons: {
    width: 'inherit',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const Actions = (props) => {
  const classes = useStyles();

  const {
    flights,
    journeyIndex,
    setLoadingBar,
  } = props;

  // statuses
  const status = Object.freeze({
    open: 'Open',
    gated: 'Gated',
    finalised: 'Finalised',
    departed: 'Departed',
  });

  const [checkinLoading, setCheckinLoading] = useState(false);
  const [cancelCheckinLoading, setCancelCheckinLoading] = useState(false);
  const [apisFlightIndex, setApisFlightIndex] = useState(false);
  const [apisRequired, setApisRequired] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    theme,
  } = useContext(ThemeContext);

  const checkedInFlight = flights
    .find((flight) => flight.passengers.find((passenger) => passenger.checkin))
      && flights.find((flight) => flight.passengers
        .find((passenger) => passenger.checkin)).passengers
        .map((passenger) => passenger.checkin);

  const {
    cancelCheckin,
    checkin,
    checkinFlightError,
  } = useContext(CheckinContext);

  const {
    refetchBooking,
  } = useContext(BookingContext);

  const {
    setOpenSeatMap,
    // cancelSeat,
  } = useContext(SeatMapContext);

  const handleSeatMap = () => {
    setOpenSeatMap(journeyIndex);
  };

  const handelCancelCheckin = () => {
    setCancelCheckinLoading(true);
    // cancelSeat(flights[0].passengers, journeyIndex);
    cancelCheckin(journeyIndex).then(() => {
      refetchBooking().then(() => setCancelCheckinLoading(false));
    });
  };

  const handelCheckin = () => {
    if (flights.some((flight) => flight.passengers.some((passenger) => (!passenger.acceptance.apiDataRegistered || !passenger.acceptance.adcConfirmed) && passenger.warnings.activeWarnings))) {
      setApisFlightIndex(flights.findIndex((flight) => flight.passengers.some((passenger) => (!passenger.acceptance.apiDataRegistered || !passenger.acceptance.adcConfirmed) && passenger.warnings.activeWarnings)));
      setApisRequired(true);
      setOpen(true);
    } else {
      setCheckinLoading(true);
      checkin(journeyIndex).then(() => {
        refetchBooking().then(() => setCheckinLoading(false));
      });
    }
  };

  useEffect(() => {
    if (checkinFlightError && checkinFlightError.some((error) => error.code === '17688' || error.code === '17652')) {
      // check if journeyIndex is right or wrong
      setApisFlightIndex(journeyIndex);
      setApisRequired(true);
      setOpen(true);
    }
  }, [checkinFlightError]);

  setLoadingBar(checkinLoading || cancelCheckinLoading);

  const flightOpen = (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
    >
      {checkedInFlight ? (
        <>
          <Box m={1}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<CancelCheckinIcon />}
              className={classes.cancelCheckinButton}
              onClick={() => handelCancelCheckin()}
              // disabled={printedBoarding}
            >
              <Typography color="textSecondary" variant="caption"><Text id="activeJourney-passenger-card-action-cancel-checkin" /></Typography>
            </Button>
          </Box>
          {theme !== 'DK'
          && (
          <Box m={1}>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              startIcon={<SeatmapIcon />}
              onClick={() => handleSeatMap()}
            >
              <Typography color="textSecondary" variant="caption"><Text id="activeJourney-flight-card-action-seatmap" /></Typography>
            </Button>
          </Box>
          )}
        </>
      )
        : (
          <Button
            className={classes.actionCheckInButtons}
            fullWidth
            size="small"
            color="secondary"
            variant="contained"
            onClick={() => handelCheckin()}
          >
            <Typography color="textSecondary" variant="caption"><Text id="activeJourney-passenger-card-action-checkin" /></Typography>
          </Button>

        )}
      {apisRequired && apisFlightIndex !== -1 ? (
        <AcceptanceModal
          passengers={flights[apisFlightIndex]?.passengers}
          flight={flights[apisFlightIndex]}
          open={open}
          journeyIndex={journeyIndex}
          setOpen={setOpen}
          setCheckinLoading={setCheckinLoading}
        />
      ) : null}
    </Box>
  );

  const flight = flights.find((item) => item.status === 'Open');

  let statusCompnent = <></>;
  switch (flight && flight.status) {
    case status.open:
      statusCompnent = (
        flightOpen
      );
      break;
    case status.gated:
      statusCompnent = (
        <Box className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="caption"><Text id="flight-status-warning-checkin-gated" /></Typography>
        </Box>
      );
      break;
    case status.departed:
      statusCompnent = (
        <Box className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="caption"><Text id="flight-status-warning-checkin-departed" /></Typography>
        </Box>
      );
      break;
    case status.finalised:
      statusCompnent = (
        <Box className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="caption"><Text id="flight-status-warning-checkin-finalised" /></Typography>
        </Box>
      );
      break;
    default:
      statusCompnent = (
        <Box className={classes.warning}>
          <WarningIcon className={classes.warningIcon} />
          <Typography variant="caption"><Text id="flight-status-warning-checkin-not-open" /></Typography>
        </Box>
      );
  }

  return (statusCompnent);
};

export default Actions;
