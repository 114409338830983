import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import LinearProgress from '@material-ui/core/LinearProgress';

// Styles
const useStyles = makeStyles({
  content: {
    display: 'flex',
    padding: 16,
  },
  loading: {
    width: '100%',
  },
});

const AcceptanceControls = (props) => {
  const classes = useStyles();

  // Props
  const {
    toggle, toggleName, loading, show,
  } = props;

  let progressBar;
  if (loading) {
    progressBar = <LinearProgress className={classes.loading} color="secondary" />;
  }

  let toggleSwitch;
  if (toggle) {
    toggleSwitch = (
      <Grid item>
        <FormControlLabel
          control={(
            <Switch
              checked={show}
              onChange={toggle}
            />
          )}
          label={toggleName}
        />
      </Grid>
    );
  }

  return (
    <>
      <Grid className={classes.actionBar}>
        {progressBar}
        <Grid container className={classes.content}>
          {toggleSwitch}
        </Grid>
      </Grid>
    </>
  );
};

AcceptanceControls.propTypes = {
  loading: PropTypes.bool,
  show: PropTypes.bool,
  toggleName: PropTypes.string,
  toggle: PropTypes.func,
};

export default AcceptanceControls;
