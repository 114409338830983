import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import Card from '../../../UI/Card/Card';
import SummaryHead from './SummaryHead/SummaryHead';
import DetailedSummary from './DetailedSummary/DetailedSummary';
import Actions from './Actions/Actions';
import Text from '../../../UI/Text/Text';

const useStyles = makeStyles((theme) => ({
  expandIcon: {
    height: '0.6em',
    width: '0.6em',
  },
  iconButton: {
    padding: 0,
  },
  mobileExpand: {
    backgroundColor: theme.palette.lightBackground.main,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

const SummaryCard = (props) => {
  const {
    flights,
    open = false,
  } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = useState(open);

  const expandedBtn = (
    <IconButton onClick={(() => setExpanded(!expanded))} className={classes.iconButton}>
      {!expanded
        ? (
          <>
            <Typography variant="subtitle2">
              <Text id="summary-card-expand-show" />
            </Typography>
            <ExpandMoreIcon className={classes.expandIcon} />
          </>
        )
        : (
          <>
            <Typography variant="subtitle2">
              <Text id="summary-card-expand-hide" />
            </Typography>
            <ExpandLessIcon className={classes.expandIcon} />
          </>
        )}
    </IconButton>
  );

  return (
    <Card
      middleSection={(
        <Box py={0}>
          <Box display="flex" py={1} justifyContent="center" className={classes.mobileExpand}>
            {expandedBtn}
          </Box>
          <SummaryHead flights={flights} />
        </Box>
      )}
      bottomSection={(
        <DetailedSummary
          flights={flights}
          expanded={expanded}
          setExpanded={setExpanded}
          expandedBtn={expandedBtn}
        />
      )}
      actions={(
        <Actions
          flight={flights[0]}
        />
      )}
    />
  );
};

SummaryCard.propTypes = {
  flights: PropTypes.array,
};

export default SummaryCard;
