/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { NavigationContext } from '../../context/navigation';
import { BookingContext } from '../../context/booking';
import { CheckinContext } from '../../context/checkin';
import Navigation from '../../hoc/Navigation/Navigation';
import JourneyCardList from '../../components/Checkin/JourneyCardList/JourneyCardList';
import Text from '../../components/UI/Text/Text';
import Notification from '../../components/UI/Notification/Notification';
import SeatInfoContainer from '../SeatInfoContainer/SeatInfoContainer';
import * as routes from '../../Routes';
import Loading from '../../components/UI/Loading/Loading';

const Checkin = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [refreshed, setRefreshed] = useState(false);

  const {
    setStep,
  } = useContext(NavigationContext);

  const {
    booking,
    autoCheckinCompleted,
    autoCheckin,
    refetchBooking,
    setAutoCheckinCompleted,
  } = useContext(BookingContext);

  const {
    checkinError,
    // checkinWarning,
    cancelCheckinError,
    checkin,
    // clearWarnings,
    clearErrors,
    checkinFlightError,
  } = useContext(CheckinContext);

  useEffect(() => {
    setStep(0);
  }, [setStep]);

  useEffect(() => {
    if (!refreshed) {
      setLoading(true);
      setRefreshed(true);
      refetchBooking().then(() => {
        setLoading(false);
      });
    }
  }, [refreshed, setRefreshed, refetchBooking]);

  useEffect(() => {
    if (!booking) {
      history.push(routes.BOOKING_SEARCH_NO_BOOKING);
    }
  }, [booking, history]);

  const handleSubmit = () => {
    history.push(routes.SUMMARY);
  };

  let errorNotification;
  if (checkinError || checkinFlightError) {
    errorNotification = <Notification onClose={clearErrors} severity="error" message={<Text id="check-in-error" />} />;
    /*     if (checkinFlightError.some((error) => error.code === '17688' || error.code === '17652')) {
      setGreencard(true);
    } */
  } else if (cancelCheckinError) {
    errorNotification = <Notification onClose={clearErrors} severity="error" message={<Text id="cancel-check-in-error" />} />;
  }

  // let warningNotification;
  // if (checkinWarning) {
  // eslint-disable-next-line max-len
  //   warningNotification = <Notification onClose={clearWarnings} severity="warning" message={checkinWarning.text} />;
  // }

  if (autoCheckin && !autoCheckinCompleted && !loading) {
    setLoading(true);

    checkin(0).then(() => {
      refetchBooking().then(() => {
        setAutoCheckinCompleted(true);
        setLoading(false);
        history.push('/boarding-pass');
      });
    });
  }

  const isAnyPaxInChecked = booking && (booking.allJourneys
    .find((journeys) => journeys.filter((flight) => flight && (flight.statuses.acceptance === 'Open') && flight.passengers
      .find((passenger) => (passenger.checkin))).length));

  return (
    (autoCheckin && !autoCheckinCompleted) ? (
      <Loading />
    ) : (
      <Box px={{ xs: 2, md: 0 }} height="100%">
        <SeatInfoContainer />
        <Box mt={2} pb={5}>
          <Container maxWidth="sm" disableGutters>
            <Typography variant="h2"><Text id="check-in-select-journey-title" /></Typography>
            {booking && booking.allJourneys && (booking.allJourneys.length > 0) && (
            <JourneyCardList
              journeys={booking.allJourneys}
            />
            )}
            <Navigation
              backText={<Text id="footer-back-button" />}
              nextText={<Text id="footer-continue-button" />}
              backRoute={routes.DANGEROUS_GOODS}
              onClickNext={handleSubmit}
            // eslint-disable-next-line max-len
              disableNext={!isAnyPaxInChecked}
              postMessage="SUMMARY"
            />
          </Container>
        </Box>
        {errorNotification}
      </Box>
    ));
};

export default Checkin;
