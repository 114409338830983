import moment from 'moment-timezone';

export const getDate = (timestamp) => timestamp && (moment(timestamp, 'MM-DD-YYYY HH:mm:ss').format('DD MMM'));

export const getTime = (timestamp) => timestamp && moment(timestamp, 'MM-DD-YYYY HH:mm:ss').format('HH:mm');

export const getTimeDifference = (t1, t2) => moment(moment(t2, 'MM-DD-YYYY HH:mm:ss').diff(moment(t1, 'MM-DD-YYYY HH:mm:ss'))).format('H[h] mm[min]');

const timeConvert = (n) => {
  const num = n;
  const hours = (num / 60);
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return `${rhours}h ${rminutes}min`;
};

export const getTimeDifferenceWithTimeZone = (t1, t2, t1tz, t2tz) => {
  try {
    const t1fixed = t1.replaceAll('-', '/');
    const t2fixed = t2.replaceAll('-', '/');
    const time1adjusted = moment(t1fixed).tz(t1tz, true).utc();
    const time2adjusted = moment(t2fixed).tz(t2tz, true).utc();
    const timeDiff = time2adjusted.diff(time1adjusted, 'minutes');
    const timeformated = timeConvert(timeDiff);
    return timeformated;
  } catch {
    return '';
  }
};
