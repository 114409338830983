// All routes
export const BOOKING_SEARCH = '/';
export const BOOKING_SEARCH_NO_BOOKING = '/?noBooking=true';
export const DANGEROUS_GOODS = '/dangerous-goods';
export const SELECT_FLIGHT = '/select-flight';
export const CHECK_IN = '/check-in';
export const SEATMAP = '/seatmap';
export const BOARDING_PASS = '/boarding-pass';
export const SUMMARY = '/summary';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const PRIVACY_POLICY = '/privacy-policy';
export const PING = '/ping';
