/* eslint-disable max-len */
import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Box } from '@material-ui/core';
import Text from '../../../UI/Text/Text';
import { ReactComponent as PassportIcon } from '../../../../assets/img/scanpassport.svg';
import { ReactComponent as PassportCompIcon } from '../../../../assets/img/scanpassportComputer.svg';
import { ReactComponent as InputPenIcon } from '../../../../assets/img/input-pen.svg';

// Styles
const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontSize: 28,
    textAlign: 'center',
  },
  title: {
    fontSize: 16,
    textAlign: 'center',
  },
  body: {
    fontSize: 14,
    textAlign: 'center',
  },
  note: {
    fontSize: 11,
    textAlign: 'center',
    color: '#FE0009',
    paddingTop: 15,
  },
  imageBox: {
    textAlign: 'center',
  },
  container: {
    [theme.breakpoints.down('960')]: {
      maxWidth: 300,
      margin: '0 auto',
    },
  },
}));

const AcceptanceHelpInfo = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid container className={classes.container} spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h1" className={classes.mainTitle}><Text id="help-info-title" /></Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={4}>
              <Typography variant="h1" className={classes.title}><Text id="help-info-mobile-title" /></Typography>
              <Box className={classes.imageBox}>
                <PassportIcon />
              </Box>
              <Typography variant="body1" className={classes.body}><Text id="help-info-mobile-body" /></Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={4}>
              <Typography variant="h1" className={classes.title}><Text id="help-info-tablet-computer-title" /></Typography>
              <Box className={classes.imageBox}>
                <PassportCompIcon />
              </Box>
              <Typography variant="body1" className={classes.body}><Text id="help-info-tablet-computer-body" /></Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box mb={4}>
              <Typography variant="h1" className={classes.title}><Text id="help-info-no-camera-passport-title" /></Typography>
              <Box className={classes.imageBox}>
                <InputPenIcon />
              </Box>
              <Typography variant="body1" className={classes.body}><Text id="help-info-no-camera-passport-body" /></Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h1" className={classes.note}><Text id="scan-warning" /></Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AcceptanceHelpInfo;
