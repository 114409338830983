import React from 'react';
import { Typography, makeStyles, Box, Button } from '@material-ui/core';
import * as routes from '../../Routes';

const useStyles = makeStyles((theme) => ({
  box: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'inherit',
    alignItems: 'center',
  },
  divider: {
    color: 'black',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  button: {
    backgroundColor: '#fff',
    height: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
}));

const CommonFooter = () => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.box}>
      <Box>
        <Button className={classes.button} href={routes.TERMS_AND_CONDITIONS} variant="text">
          Terms and conditions
        </Button>
      </Box>
      <Typography className={classes.divider}> |</Typography>
      <Box>
        <Button className={classes.button} href={routes.PRIVACY_POLICY} variant="text">
          Privacy policy
        </Button>
      </Box>
      <Typography className={classes.divider}> |</Typography>
      <Box>
        <Button className={classes.button} href="https://www.objective.se/" target="_blank" variant="text">
          Powered by Objective
        </Button>
      </Box>
    </Box>
  );
};

export default CommonFooter;
