import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  accordion: {
    marginBottom: '10px',
    boxShadow: '0 0px 24px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '4px',
    '&::before': {
      height: '0px',
    },
  },
  color: {
    color: theme.palette.primary.main,
  },
}));

const PageTitle = ({ children }) => {
  const classes = useStyles();

  return (
    <Box my={2} pt={2}>
      <Typography className={classes.color} variant="h1" component="h1">
        <Box>
          {children}
        </Box>
      </Typography>
    </Box>
  );
};

export default PageTitle;
