import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Box, DialogActions } from '@material-ui/core';
import Button from '../../../../UI/Button/Button';
import Dialog from '../../../../UI/Dialog/Dialog';
import Text from '../../../../UI/Text/Text';

// Styles
const useStyles = makeStyles({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 16px 16px 16px',
  },
  actionLeft: {
    marginRight: 8,
  },
  warningText: {
    color: '#FE0009',
  },
});

const AskContactModal = (props) => {
  const classes = useStyles();

  // Props
  const { close, showContact } = props;

  return (
    <>
      <Dialog
        open
        setOpen={close}
        title={<Text id="emergency-contact-details" />}
        action={null}
      >
        <Typography className={classes.warningText}>
          <Text id="emergency-contact-required" />
        </Typography>
        <DialogActions>
          <Box m={1} style={{ width: '100%' }}>
            <Grid container className={classes.actions}>
              <Grid item className={classes.actionLeft}>
                <Button text={<Typography variant="h4"><Text id="cancel-apis-popup" /></Typography>} variant="text" color="inherit" action={() => close(false)} />
              </Grid>
              <Grid item>
                <Button text={<Typography variant="h4"><Text id="add-details" /></Typography>} color="primary" action={() => showContact(true)} />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

AskContactModal.propTypes = {
  showContact: PropTypes.func,
  close: PropTypes.func,
};

export default AskContactModal;
