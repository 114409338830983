/* eslint-disable max-len */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../Header/Header';
import CommonFooter from '../Footer/SmallFooter';

const useStyles = makeStyles(() => ({
  body: {
    minHeight: 'calc(100vh - 141px)',
    height: '80vh',
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const showCommonFooter = location.pathname === '/' || location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions';

  const { children } = props;

  return (
    <>
      <Header />
      <main className={classes.body}>
        {children}
        {showCommonFooter && <CommonFooter />}
      </main>
    </>
  );
};

export default Layout;
