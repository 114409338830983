import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Box from '@material-ui/core/Box';

// import { Typography } from '@material-ui/core';
// import { ReactComponent as Available } from '../../../../assets/img/seat-available.svg';
// import { ReactComponent as Occupied } from '../../../../assets/img/seat-occupied.svg';
// import { ReactComponent as Selected } from '../../../../assets/img/seat-selected.svg';

// import SeatCabinHeader from './SeatCabinHeader/SeatCabinHeader';
import SeatRow from './SeatRow/SeatRow';
// import Text from '../../Text/Text';

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: '#FFF',
    color: '#FFF',
    border: 'none',
    borderTop: 'solid 1px #b0b5cb',
    marginTop: 20,
    margin: 0,
    paddingBottom: 20,
  },
}));

const SeatCabin = (props) => {
  const classes = useStyles();

  // Props
  const {
    cabin, exitRows, selectedSeats, initialSeats,
    selectSeat, flight,
  } = props;

  // Aisle locations
  const aisles = cabin.aislePositions.filter((item, i) => i % 2 === 0);

  return (
    <>
      <hr className={classes.divider} />
      {cabin.rows.map((row, i) => (
        <SeatRow
          key={i} // eslint-disable-line
          row={row}
          cols={cabin.cols}
          aisles={aisles}
          exitRows={exitRows}
          selectedSeats={selectedSeats}
          initialSeats={initialSeats}
          selectSeat={selectSeat}
          height={null}
          flight={flight}
        />
      ))}
    </>
  );
};

SeatCabin.propTypes = {
  cabin: PropTypes.object,
  exitRows: PropTypes.array,
  selectSeat: PropTypes.func,
  selectedSeats: PropTypes.array,
  initialSeats: PropTypes.array,
  flight: PropTypes.object,
};

export default SeatCabin;
