import colors from './colors';

const overrides = (muiTheme) => ({
  MuiDialog: {
    paper: {
      borderRadius: '3px',
      margin: '10px',
    },
  },
  MuiDialogContent: {
    root: {
      padding: '8px 30px',
      [muiTheme.breakpoints.down('sm')]: {
        padding: '8px 10px',
      },
    },
  },
  MuiDrawer: {
    paper: {
      backgroundColor: colors.primary.main,
      color: colors.text.secondary,
    },
  },
  MuiButton: {
    root: {
      fontFamily: 'Flama-Book',
      minHeight: 50,
      fontSize: '14px',
      textTransform: 'none',
    },
    contained: {
      borderRadius: '3px',
      boxShadow: 'none',
      '&:focus': {
        boxShadow: 'none',
      },
    },
    containedSecondary: {
      borderRadius: '3px',
      boxShadow: 'none',
      color: colors.text.secondary,
      '&:focus': {
        boxShadow: 'none',
      },
    },
    outlined: {
      borderWidth: 2,
      boxShadow: 'none',
      borderRadius: '3px',
      borderColor: colors.secondary.main,
      '&:focus': {
        borderWidth: 2,
        boxShadow: 'none',
      },
    },
    outlinedSecondary: {
      borderWidth: 2,
      boxShadow: 'none',
      borderRadius: '3px',
      borderColor: colors.secondary.main,
      '&:focus': {
        borderWidth: 2,
        boxShadow: 'none',
      },
    },
    sizeSmall: {
      minHeight: 29,
    },
  },
  MuiOutlinedInput: {
    root: {
      backgroundColor: colors.input.background,
      borderRadius: '3px',
    },
    notchedOutline: {
      borderColor: colors.input.border,
    },
  },
  MuiInputLabel: {
    root: {
      color: colors.input.label,
    },
  },
  MuiStepper: {
    root: {
      backgroundColor: colors.primary.main,
      padding: 8,
    },
  },
  MuiStepConnector: {
    root: {
      '&$disabled': {
        '& $line': {
          borderColor: colors.stepper.line,
          opacity: 0.35,
        },
      },
    },
    line: {
      borderColor: colors.stepper.line,
    },
    lineHorizontal: {
      margin: '3px 25px 3px 25px',
      borderTopWidth: 2,
    },
    alternativeLabel: {
      left: 'calc(-50%)',
      right: 'calc(50%)',
    },
    active: {
      '& $line': {
        borderColor: colors.stepper.line,
      },
    },
    completed: {
      '& $line': {
        borderColor: colors.stepper.line,
      },
    },
  },
  MuiStepIcon: {
    root: {
      fontSize: 32,
      borderRadius: '50%',
      borderWidth: 3,
      color: colors.primary.main,
      backgroundColor: colors.stepper.icon,
      opacity: 0.35,
      border: `2px solid ${colors.stepper.icon}`,
      '&$active': {
        opacity: 1,
      },
      '&$completed': {
        opacity: 1,
      },
    },
  },
  MuiStepLabel: {
    label: {
      fontSize: 12,
      marginTop: '6px !important',
      color: `${colors.stepper.text} !important`,
    },
    iconContainer: {
      zIndex: 1,
    },
  },
  MuiTabs: {
    root: {
      minHeight: '10px !important',
      height: '40px !important',
      placeItems: 'center',
      border: `solid 1px ${colors.text.primary}`,
      borderRadius: '3px',
    },
    indicator: {
      backgroundColor: 'transparent !important',
    },
  },
  MuiTab: {
    root: {
      fontSize: '10px',
      '&$selected': {
        background: `${colors.primary.main} !important`,
        color: `${colors.text.secondary} !important`,
      },
    },
  },
  MuiCheckbox: {
    root: {
      color: `${colors.secondary.main} !important`,
    },
  },
}
);

export default overrides;
