const colors = {
  background: {
    main: '#F8F8FB',
  },
  lightBackground: {
    main: '#DDF0DE',
  },
  primary: {
    main: '#369e3b',
  },
  secondary: {
    main: '#369e3b',
  },
  error: {
    main: '#FE0009',
  },
  success: {
    main: '#00A411',
  },
  text: {
    primary: '#000',
    secondary: '#FFF',
  },
  warning: {
    main: '#eca500',
  },
  input: {
    background: '#FFF',
    label: '#000',
    border: '#000',
  },
  alternative: {
    contrast: '#EFEFEF',
  },
  stepper: {
    icon: '#FFF',
    line: '#FFF',
    text: '#FFF',
  },
};

export default colors;
