import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MaterialCard from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '3px',
  },
  content: {
    padding: 0,
  },
  divider: {
    backgroundColor: '#FFF',
    color: '#FFF',
    border: 'none',
    borderTop: '1px dashed black',
    padding: 0,
    margin: 0,
  },
  solidDivider: {
    borderTop: '1px solid #E9E9E9',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 50,
  },
}));

const Card = (props) => {
  const classes = useStyles();

  const {
    topSection,
    middleSection,
    bottomSection,
    solidDivider,
    actions,
    loadingBar = false,
  } = props;

  return (
    <MaterialCard className={classes.root}>
      <CardContent className={classes.content}>
        {topSection}
        {middleSection}
        {bottomSection}
      </CardContent>
      {actions
      && (solidDivider ? (
        <hr className={clsx(classes.divider, classes.solidDivider)} />
      ) : (
        <hr className={classes.divider} />
      ))}
      {actions && (
      <>
        <CardActions className={classes.actions}>
          {actions}
        </CardActions>
        {loadingBar && (
        <Box className={classes.root}>
          <LinearProgress />
        </Box>
        )}
      </>
      )}
    </MaterialCard>
  );
};

Card.propTypes = {
  topSection: PropTypes.object,
  middleSection: PropTypes.object,
  bottomSection: PropTypes.object,
  actions: PropTypes.object,
};

export default Card;
