import React from 'react';
import { Container, Fade, Box } from '@material-ui/core';

const FadeInContainer = (props) => {
  const {
    children, disableGutters, maxWidth, mx,
  } = props;

  return (
    <Fade in mountOnEnter unmountOnExit>
      <Box mx={mx || [0, 10, 25]}>
        <Container
          maxWidth={maxWidth ?? 'xs'}
          disableGutters={disableGutters}
        >
          {children}
        </Container>
      </Box>
    </Fade>
  );
};

export default FadeInContainer;
