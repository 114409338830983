import React from 'react';

import MaterialDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import CloseIcon from '@material-ui/icons/Close';

import { useLocation } from 'react-router-dom';
import Text from '../../components/UI/Text/Text';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import Logo from '../../assets/img/logo.png';
import { wwwDir } from '../../utils/cordovaGlobals';

const useStyles = makeStyles(() => ({
  listItem: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 18,
  },
  closeIcon: {
    fontSize: 32,
  },
  logo: {
    height: 30,
  },
}));

const Drawer = (props) => {
  const classes = useStyles();
  const location = useLocation();

  const { open, setOpen, restartCheckIn } = props;

  return (
    <MaterialDrawer anchor="top" onOpen={() => setOpen(true)} open={open} onClose={() => setOpen(false)}>
      <Box display="flex">
        <Box p={1}>
          <img className={classes.logo} src={`${wwwDir}${Logo}`} alt="logo" />
        </Box>
        <Box ml="auto" p={1}>
          <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
        </Box>
      </Box>
      <List>
        {location.pathname !== '/'
          ? (
            <ListItem className={classes.listItem} onClick={restartCheckIn}>
              <Typography><Text id="drawer-restart-checkin" /></Typography>
            </ListItem>
          ) : null}
        <ListItem className={classes.listItem}>
          <LanguageSelect />
        </ListItem>
      </List>
    </MaterialDrawer>
  );
};

export default Drawer;
