import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import { DialogActions, Typography } from '@material-ui/core';
import Button from '../../../../UI/Button/Button';
import Dialog from '../../../../UI/Dialog/Dialog';
import Text from '../../../../UI/Text/Text';

// Styles
const useStyles = makeStyles((theme) => ({
  condition: {
    marginBottom: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 16px 16px 16px',
  },
  actionLeft: {
    marginRight: theme.spacing(1),
  },
}));

const ConditionModal = (props) => {
  const classes = useStyles();

  // Props
  const {
    loading, close, updateADC,
  } = props;

  // Progress bar
  let progressBar;
  if (loading) {
    progressBar = <LinearProgress color="secondary" />;
  }

  return (
    <>
      <Dialog
        open
        setOpen={close}
        title={<Text id="confirm-ADC-conditions" />}
        action={null}
      >
        <>
          <Grid container>
            {/*             {conditions.map((condition) => (
              <Grid className={classes.condition} key={condition.code}>
                {condition.text}
              </Grid>
            ))} */}
          </Grid>
          {progressBar}
        </>
        <DialogActions>
          <Grid container className={classes.actions}>
            <Grid item className={classes.actionLeft}>
              <Button text={<Typography variant="h4"><Text id="cancel-apis-popup" /></Typography>} variant="text" color="inherit" action={() => close(false)} />
            </Grid>
            <Grid item>
              <Button text={<Typography variant="h4"><Text id="confirm-ADC" /></Typography>} color="primary" action={() => updateADC()} />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

ConditionModal.propTypes = {
  updateADC: PropTypes.func,
  close: PropTypes.func,
  loading: PropTypes.bool,
};

export default ConditionModal;
