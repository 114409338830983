/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import React, { useState, createContext, useEffect, useCallback } from 'react';
import * as api from '../api';

export const BookingContext = createContext();

export const BookingProvider = (props) => {
  const { children } = props;
  const [unfilteredBooking, setUnfilteredBooking] = useState();
  const [inputData, setInputData] = useState();
  const [bookingLoading, setBookingLoading] = useState(false);
  const [bookingError, setBookingError] = useState();
  const [activeJourneyIndex, setActiveJourneyIndex] = useState(-1);
  const [activePassengers, setActivePassengers] = useState([]);
  const [passengerIdentifier, setPassengerIdentifier] = useState('');
  const [autoCheckin, setAutoCheckin] = useState(false);
  const [autoCheckinCompleted, setAutoCheckinCompleted] = useState(false);

  const filterBooking = useCallback(
    () => (unfilteredBooking && {
      ...unfilteredBooking,
      primeJourney: unfilteredBooking.primeJourney.map((journey) => (
        {
          ...journey,
          passengers: journey.passengers.filter((_x, i) => activePassengers[i]),
        })),
      alternateJourneys: unfilteredBooking.alternateJourneys.map(
        (flight) => flight.map((journey) => (
          {
            ...journey,
            passengers: journey.passengers.filter((_x, i) => activePassengers[i]),
          }
        )),
      ),
    }
    ),
    [unfilteredBooking, activePassengers],
  );

  useEffect(() => {
    if (unfilteredBooking && activePassengers.length === 0) {
      setActivePassengers(unfilteredBooking.primeJourney[0].passengers.map((pax) => {
        if (passengerIdentifier) {
          return pax.passengerIdentifier === passengerIdentifier;
        }
        return true;
      }));
    }
  }, [activePassengers.length, unfilteredBooking, passengerIdentifier]);

  const booking = filterBooking();
  if (booking) {
    booking.allJourneys = booking ? [booking.primeJourney].concat(booking.alternateJourneys) : [];
  }

  const fetchBookingByPNRandPI = (pnr, pi) => {
    setBookingError();
    setBookingLoading(true);
    return api.get(
      `/dcsidc/cprIdentification/fromPNRandPI?PNR=${pnr}&PI=${pi}`,
      (b) => {
        setUnfilteredBooking(b);
        setInputData({ PNRandPI: { pnr, pi } });
      },
      setBookingError,
      setBookingLoading(false),
    );
  };

  const fetchBookingByPNRAndName = (pnr, surname) => {
    setBookingError();
    setBookingLoading(true);
    return api.get(
      `/dcsidc/cprIdentification/fromPNRAndName?PNR=${pnr}&surname=${surname}`,
      (b) => {
        setUnfilteredBooking(b);
        setInputData({ PNRAndName: { pnr, surname } });
        return b;
      },
      setBookingError,
      setBookingLoading(false),
    );
  };

  const fetchBookingByTicketAndName = (ticket, surname) => {
    setBookingError();
    setBookingLoading(true);
    return api.get(
      `/dcsidc/cprIdentification/fromTicketAndName?ticket=${ticket}&surname=${surname}`,
      (b) => {
        setUnfilteredBooking(b);
        setInputData({ ticketAndName: { ticket, surname } });
      },
      setBookingError,
      setBookingLoading(false),
    );
  };

  const refetchBooking = () => {
    if (inputData && inputData.ticketAndName) {
      return fetchBookingByTicketAndName(inputData.ticketAndName.ticket, inputData.ticketAndName.surname);
    }
    if (inputData && inputData.PNRAndName) {
      return fetchBookingByPNRAndName(inputData.PNRAndName.pnr, inputData.PNRAndName.surname);
    } if (inputData && inputData.PNRandPI) {
      return fetchBookingByPNRandPI(inputData.PNRandPI.pnr, inputData.PNRandPI.pi);
    }
    const emptyPromise = Promise.resolve('');
    return emptyPromise;
  };

  const clearBooking = () => {
    setUnfilteredBooking();
    setInputData();
    setInputData();
  };

  const activeJourney = ((booking && activeJourneyIndex !== -1)
    && (activeJourneyIndex.primeJourney
      ? (booking.primeJourney && booking.primeJourney)
      : booking.alternateJourneys
      && booking.alternateJourneys[activeJourneyIndex.alternateJourneys.index]));

  const values = {
    booking,
    clearBooking,
    bookingError,
    bookingLoading,
    refetchBooking,
    activePassengers,
    setActivePassengers,
    fetchBookingByPNRAndName,
    fetchBookingByTicketAndName,
    fetchBookingByPNRandPI,
    activeJourney,
    setActiveJourneyIndex,
    unfilteredBooking,
    setPassengerIdentifier,
    passengerIdentifier,
    setAutoCheckin,
    autoCheckin,
    autoCheckinCompleted,
    setAutoCheckinCompleted,
    setBookingError,
  };

  return (
    <BookingContext.Provider value={values}>
      {children}
    </BookingContext.Provider>
  );
};
