import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { getDate, getTime } from '../../../../utils/date';
import { ReactComponent as AirplaneIcon } from '../../../../assets/img/planeRight.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 10,
    backgroundColor: theme.palette.lightBackground.main,
  },
  header: {
    fontSize: 9,
    color: '#082A36',
  },
  mobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  desktopAndPads: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const FlightSummary = (props) => {
  const classes = useStyles();

  const {
    flight,
    className,
  } = props;

  return (
    <Box className={clsx(classes.root, className)}>
      <Box display="flex">
        <Box display="flex" flexDirection="row">
          <Box>
            <Typography variant="h4">
              {flight.station && flight.station.from && `${flight.station.from.name}(${flight.station.from.code})`}
            </Typography>
            <Typography variant="subtitle1">
              {getTime(flight.flightTime.scheduledDeparture)}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" mx={1.5} mt={1}>
          <AirplaneIcon />
        </Box>
        <Box display="flex" flexDirection="row">
          <Box>
            <Typography variant="h4">
              {flight.station && `${flight.station.to.name}(${flight.station.to.code})`}
            </Typography>
            <Typography variant="subtitle1">
              {getTime(flight.flightTime.scheduledArrival)}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.desktopAndPads} display="flex" flexGrow={1} mx={{ xs: 0, md: 4 }} mb={{ xs: 0, md: 2 }} pt={{ xs: 2, md: 0 }} flexDirection="column" justifyContent="center">
          <Typography variant="h6">
            {`${flight.carrier}${flight.flightNr}`}
          </Typography>
        </Box>
        <Box className={classes.desktopAndPads} display="flex" flexDirection="column" pt={{ xs: 2, md: 0 }} justifyContent="center">
          <Typography variant="h6">
            {`${getDate(flight.flightTime.scheduledDeparture)}`}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.mobile} display="flex" flexDirection="row" alignContent="flex-end" justifyContent="flex-end">
        <Box mx={{ xs: 2, md: 4 }}>
          <Typography variant="h6">
            {`${flight.carrier}${flight.flightNr}`}
          </Typography>
        </Box>
        <Box>
          <Typography variant="h6">
            {`${getDate(flight.flightTime.scheduledDeparture)}`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

FlightSummary.propTypes = {
  flight: PropTypes.object,
};

export default FlightSummary;
