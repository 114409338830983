import axios from 'axios';
import * as Sentry from '@sentry/react';

// API domain
let domain = 'https://checkin-api.test.gl.objective.se/api';
if (process.env.REACT_APP_BACKEND_API) {
  domain = process.env.REACT_APP_BACKEND_API;
  if (process.env.REACT_APP_BACKEND_VERSION) {
    domain = `${domain}/${process.env.REACT_APP_BACKEND_VERSION}`;
  }
}

const header = (token) => token && { headers: { Authorization: token } };

const sendToSentry = (error) => {
  Sentry.captureException(error);
};

// Error
// TODO Handle
const handleNetworkError = (errorFunction, error) => {
  sendToSentry(error);
  return errorFunction('Network error');
};

// Amadeus error
const handleAmadeusError = (response, errorFunction) => {
  const { data } = response;
  const { error } = data;
  sendToSentry(error);

  // Error object
  const errorObject = {
    message: error.text,
    type: error.type,
    timeStamp: data.timeStamp,
    code: error.code,
  };

  return errorFunction(errorObject);
};

// Response
const handleResponse = (response, successFunction, errorFunction, body) => {
  if (response.data && response.data.error && errorFunction) {
    return handleAmadeusError(response, errorFunction);
  }
  return successFunction(response.data, body);
};

// GET
export const get = (path, successFunction, errorFunction, token) => axios
  .get(domain + path, header(token))
  .then((response) => handleResponse(response, successFunction, errorFunction))
  .catch((error) => handleNetworkError(errorFunction, error));

// PATCH
export const patch = (path, body, successFunction, errorFunction, token) => axios
  .patch(domain + path, body, header(token))
  .then((response) => handleResponse(response, successFunction, errorFunction, body))
  .catch((error) => handleNetworkError(errorFunction, error));

// POST
export const post = (path, body, successFunction, errorFunction, token) => axios
  .post(domain + path, body, header(token))
  .then((response) => handleResponse(response, successFunction, errorFunction))
  .catch((error) => handleNetworkError(errorFunction, error));
// DELETE
export const del = (path, body, successFunction, errorFunction, token) => axios
  .delete(domain + path, { data: body, headers: { Authorization: token } })
  .then((response) => handleResponse(response, successFunction, errorFunction))
  .catch((error) => handleNetworkError(errorFunction, error));
