/* eslint-disable max-len */
import { whitelistCarriers } from './carrierWhitelist';

export const checkCarrierWhitelist = (bookings, allCarriers) => {
  let response;
  if (allCarriers && allCarriers === 'Y') {
    return response;
  }
  const foundCarrier = bookings.primeJourney && bookings.primeJourney.find((flight) => whitelistCarriers.includes(flight.carrier));
  if (!foundCarrier) {
    response = {
      message: 'whitelist-error',
      type: 'error',
      timeStamp: bookings.timeStamp,
      code: '1337',
    };
    return response;
  }
  return response;
};
