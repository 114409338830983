import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { NavigationContext } from '../../context/navigation';
import { BookingContext } from '../../context/booking';
import SummaryCardList from '../../components/Summary/SummaryCardList/SummaryCardList';
import Navigation from '../../hoc/Navigation/Navigation';
import Text from '../../components/UI/Text/Text';
import * as routes from '../../Routes';

const Summary = () => {
  const history = useHistory();

  const {
    setStep,
  } = useContext(NavigationContext);

  const {
    booking,
  } = useContext(BookingContext);

  useEffect(() => {
    setStep(1);
  }, [setStep]);

  useEffect(() => {
    if (!booking) {
      history.push(routes.BOOKING_SEARCH_NO_BOOKING);
    }
  }, [booking, history]);

  const checkedInJourneys = booking
    && booking.allJourneys && booking.allJourneys.filter((flights) => flights
    .find((flight) => flight.statuses.acceptance === 'Open' && flight.passengers.find((passenger) => passenger.checkin))
    && flights.find((flight) => flight.passengers
      .find((passenger) => passenger.checkin)).passengers
      .map((passenger) => passenger.checkin));

  return (
    <Container maxWidth="sm">
      <Box my={5}>
        <Typography variant="h2"><Text id="summary-title" /></Typography>
        {checkedInJourneys && (
          <SummaryCardList
            journeys={checkedInJourneys}
          />
        )}
        <Navigation
          backText={<Text id="footer-back-button" />}
          nextText={<Text id="summary-card-summary-table-status-collect-boarding-pass" />}
          backRoute={routes.CHECK_IN}
          nextRoute={routes.BOARDING_PASS}
          // nextAlink={appEmbedded && '/?didCompleteCheckin=true'}
          postMessage="/BOARDING_PASS"
        />
      </Box>
    </Container>
  );
};

export default Summary;
