import React, { useContext } from 'react';

import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as CircularProgress } from '../../../assets/img/loader.svg';
import { ReactComponent as CircularProgressDX } from '../../../assets/img/DX/loaderDX.svg';
import { ReactComponent as CircularProgressDK } from '../../../assets/img/DK/loaderDK.svg';
import { ReactComponent as CircularProgressBIXVing } from '../../../assets/img/BIX_VING/loaderBIX.svg';
import { ReactComponent as CircularProgressBIXApollo } from '../../../assets/img/BIX_APOLLO/loaderBIX.svg';
import { ThemeContext } from '../../../context/theme';

const useStyles = makeStyles((theme) => ({
  rotate: {
    animation: '$rotation 1.5s infinite ease-in-out',
    'transform-origin': 'center center',
    marginTop: (props) => (props.noMargin ? 0 : theme.spacing(15)),
  },
  '@keyframes rotation': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  color: (props) => props.color && props.color,
  marginTop: theme.spacing(2.5),
}));

const Loading = (props) => {
  const classes = useStyles(props);
  const {
    theme,
  } = useContext(ThemeContext);

  let circularProgress = <CircularProgress alt="Loading" className={classes.rotate} height={100} />;
  if (theme === 'DX') {
    circularProgress = <CircularProgressDX alt="Loading DX" className={classes.rotate} height={100} />;
  } else if (theme === 'DK') {
    circularProgress = <CircularProgressDK alt="Loading DK" className={classes.rotate} height={100} />;
  } else if (theme === 'BIX_VING') {
    circularProgress = <CircularProgressBIXVing alt="Loading BIX_VING" className={classes.rotate} height={100} />;
  } else if (theme === 'BIX_APOLLO') {
    circularProgress = <CircularProgressBIXApollo alt="Loading BIX_APOLLO" className={classes.rotate} height={100} />;
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {circularProgress}
    </Box>
  );
};

Loading.propTypes = {
};

export default Loading;
