import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Box from '@material-ui/core/Box';

import JourneyCard from './JourneyCard/JourneyCard';

const FlightCardList = (props) => {
  const {
    journeys,
  } = props;

  return (
    <Box pt={1}>
      {journeys && journeys.map((journey, index) => (
        <Box
          pb={2}
          key={journey[0].flightNr + journey[0].station.from.code}
          style={{ display: (moment().diff(moment(journey[0].flightTime.scheduledDeparture, 'MM-DD-YYYY HH:mm:ss'), 'days') < 4) ? 'block' : 'none' }}
        >
          <JourneyCard
            journeyIndex={index}
            journey={journey}
          />
        </Box>
      ))}
    </Box>
  );
};

FlightCardList.propTypes = {
  journeys: PropTypes.array,
};

export default FlightCardList;
