import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { Typography } from '@material-ui/core';
import { ReactComponent as Available } from '../../../assets/img/seat-available.svg';
import { ReactComponent as Occupied } from '../../../assets/img/seat-occupied.svg';
import { ReactComponent as Selected } from '../../../assets/img/seat-selected.svg';
import Text from '../Text/Text';

import SeatCabin from './SeatCabin/SeatCabin';

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(1),
    },
  },
  container: {
    backgroundColor: 'white',
    padding: theme.spacing(3),
    textAlign: '-webkit-center',
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(2),
    },
  },
}));

const SeatMap = (props) => {
  const classes = useStyles();

  // Props
  const {
    seatmap, selectSeat, selectedSeats, initials,
    flight, activePassenger,
  } = props;

  // State
  const [change, setChange] = useState(false);

  // Refs
  const changeRef = useRef(change);
  const activePassengerRef = useRef(activePassenger);
  const initialSeats = useRef(selectedSeats);

  useEffect(() => {
    if (!initialSeats.current) {
      initialSeats.current = selectedSeats;
    }
  }, [selectedSeats]);

  // Update change ref on change
  useEffect(() => {
    changeRef.current = change;
  }, [change]);

  // Interval
  useEffect(() => {
    if (activePassenger !== activePassengerRef.current) {
      activePassengerRef.current = activePassenger;
      setChange(false);
    }
  }, [changeRef, activePassenger]);

  // Show selected seats
  let showSelected = [];
  if (selectedSeats) {
    showSelected = selectedSeats.map((seat, i) => ({
      seat,
      showSeat: initials[i],
      active: change ? false : i === activePassenger,
    }));
  }

  return (
    <Box pb={2}>
      <Grid className={classes.container}>
        <Grid container>
          <Grid item xs={4}>
            <Box width="100%" textAlign="center">
              <Available />
              <Typography><Text id="seat-map-available-seat" /></Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box width="100%" textAlign="center">
              <Occupied />
              <Typography><Text id="seat-map-occupied-seat" /></Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box width="100%" textAlign="center">
              <Box position="relative">
                <Selected />
                <Box position="absolute" style={{ fontSize: '14px' }} width="100%" top="20%">
                  <Typography variant="inherit" color="textSecondary">{selectedSeats && selectedSeats[activePassenger] && selectedSeats[activePassenger].showSeat}</Typography>
                </Box>
              </Box>
              <Typography><Text id="seat-map-selected-seat" /></Typography>
            </Box>
          </Grid>
        </Grid>
        {seatmap.cabins.map((cabin) => (
          <SeatCabin
            key={cabin.cabinClass}
            cabin={cabin}
            exitRows={seatmap.exitRows}
            selectedSeats={showSelected}
            initialSeats={initialSeats.current}
            selectSeat={selectSeat}
            activePassenger={activePassenger}
            flight={flight}
          />
        ))}
      </Grid>
    </Box>
  );
};

SeatMap.propTypes = {
  initials: PropTypes.array,
  selectSeat: PropTypes.func,
  selectedSeats: PropTypes.array,
  flight: PropTypes.object,
};

export default SeatMap;
