/* eslint-disable react/jsx-indent */
/* eslint-disable no-useless-escape */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { IconButton, InputAdornment } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import Text from '../UI/Text/Text';
import Dialog from '../UI/Dialog/Dialog';
import { ThemeContext } from '../../context/theme';

const useStyles = makeStyles({
  uppercase: {
    textTransform: 'uppercase',
    fontSize: '16px',
  },
});

const SearchForm = (props) => {
  const classes = useStyles();

  const {
    handleSubmitPNR,
    handleSubmitTicket,
  } = props;

  const {
    theme,
  } = useContext(ThemeContext);

  const [lastName, setLastName] = useState('');
  const [pnr, setPnr] = useState('');
  const [etktNumber, setEtktNumber] = useState('');
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [infoDialogText, setInfoDialogText] = useState(null);

  const validLastName = lastName && lastName.length > 0;
  const validPnr = (pnr && ((pnr.length >= 6 && theme !== 'DK') || (pnr.length === 8 && theme === 'DK')));
  const validEtktNumber = etktNumber && (etktNumber.replace('-', '').length === 15 || etktNumber.replace('-', '').length === 13) && etktNumber.match(/^[0-9,]*\-?[0-9,]*$/) != null;
  const validInputBookingRef = (validLastName && validPnr);
  const validInputEtkt = (validLastName && validEtktNumber);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openInfoDialog = (textRef) => {
    setInfoDialogText(<Text id={textRef} />);
    setShowInfoDialog(true);
  };

  return (
    <Box>
      <Dialog open={showInfoDialog} setOpen={setShowInfoDialog} action={null}>
        {infoDialogText}
      </Dialog>
      <Box my={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
        >
          <Tab label={<Text id="booking-search-form-booking-reference" />} />
          <Tab label={<Text id="booking-search-form-e-ticket-number" />} />
        </Tabs>
      </Box>
      {(value === 0)
        ? (
          <>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                id="textfield-lastname"
                label={<Text id="booking-search-form-input-name" />}
                value={lastName}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={() => openInfoDialog('booking-search-info-lastname')}>
                        <InfoIcon />
                      </IconButton>
                    </InputAdornment>,
                  classes: {
                    input: classes.uppercase,
                  },
                }}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                id="textfield-pnr"
                label={<Text id="booking-search-form-input-pnr" />}
                value={pnr}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={() => openInfoDialog(theme !== 'DK' ? 'booking-search-info-booking-reference' : 'booking-search-info-booking-reference-dk')}>
                        <InfoIcon />
                      </IconButton>
                    </InputAdornment>,
                  classes: {
                    input: classes.uppercase,
                  },
                }}
                onChange={(event) => setPnr(event.target.value)}
              />
            </Box>
            <Box mt={2}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                disabled={!validInputBookingRef}
                onClick={() => handleSubmitPNR(pnr.toUpperCase(), lastName.toUpperCase())}
              >
                <Text id="footer-continue-button" />
              </Button>
            </Box>
          </>
        )
        : (
          <>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                id="textfield-lastname"
                label={<Text id="booking-search-form-input-name" />}
                value={lastName}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={() => openInfoDialog('booking-search-info-lastname')}>
                        <InfoIcon />
                      </IconButton>
                    </InputAdornment>,
                  classes: {
                    input: classes.uppercase,
                  },
                }}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                variant="outlined"
                id="textfield-etkt"
                label={<Text id="booking-search-form-input-etkt" />}
                value={etktNumber}
                onChange={(event) => setEtktNumber(event.target.value)}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton onClick={() => openInfoDialog('booking-search-info-etkt')}>
                        <InfoIcon />
                      </IconButton>
                    </InputAdornment>,
                  classes: {
                    input: classes.uppercase,
                  },
                }}
              />
            </Box>
            <Box mt={2}>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                disabled={!validInputEtkt}
                onClick={() => handleSubmitTicket(etktNumber.toUpperCase(), lastName.toUpperCase())}
              >
                <Text id="footer-continue-button" />
              </Button>
            </Box>
          </>
        )}
    </Box>
  );
};

SearchForm.propTypes = {
  handleSubmitPNR: PropTypes.func,
  handleSubmitTicket: PropTypes.func,
};

export default SearchForm;
