import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import PassengerInfo from '../../../PassengerInfo/PassengerInfo';

import Text from '../../../../UI/Text/Text';

const useStyles = makeStyles({
  root: {
    padding: 10,
  },
  header: {
    fontSize: 9,
    color: '#082A36',
  },
  name: {
    textTransform: 'capitalize',
  },
});

const PassengerList = (props) => {
  const classes = useStyles();

  const {
    passengers, journey,
  } = props;

  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [open, setOpen] = useState(false);

  const openGenderDialog = (passenger) => {
    setOpen(true);
    setSelectedPassenger(passenger);
  };

  return (
    <Box px={3} py={2}>
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-table-header-name" /></Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className={classes.header}><Text id="summary-card-table-header-status" /></Typography>
        </Grid>
      </Grid>
      {passengers && passengers.map((passenger) => (
        <Grid container key={passenger.passengerFlightIdentifier}>
          <Grid item xs={8}>
            <Typography variant="subtitle1" className={classes.name}>{passenger.name ? `${passenger.name.firstName} ${passenger.name.surName}`.toLowerCase() : '-'}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1" color={passenger.checkin ? 'secondary' : 'error'}>{passenger.checkin ? 'Checked in' : 'Not checked in'}</Typography>
          </Grid>
          {!passenger.gender ? (
            <Grid item xs={12}>
              <Box mt={1}>
                <Button
                  size="small"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => openGenderDialog(passenger)}
                >
                  <Typography color="textSecondary" variant="caption"><Text id="activeJourney-passenger-card-action-update-pax-info" /></Typography>
                </Button>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      ))}
      {open && selectedPassenger ? (
        <PassengerInfo
          passenger={selectedPassenger}
          flight={journey}
          open={open}
          setOpen={setOpen}
        />
      ) : null}

    </Box>
  );
};

PassengerList.propTypes = {
  passengers: PropTypes.array,
};

export default PassengerList;
