import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Box, DialogActions } from '@material-ui/core';
import Button from '../../../../UI/Button/Button';
import Dialog from '../../../../UI/Dialog/Dialog';

// Styles
const useStyles = makeStyles({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 16px 16px 16px',
  },
  actionLeft: {
    marginRight: 8,
  },
});

const AskDocumentModal = (props) => {
  const classes = useStyles();

  // Props
  const { close, startScan } = props;

  return (
    <>
      <Dialog
        open
        setOpen={close}
        title="Scan Regulatory Document"
        action={null}
      >
        <Typography>
          Would you like to scan a regulatory document for this passenger?
        </Typography>
        <DialogActions>
          <Box m={1} style={{ width: '100%' }}>
            <Grid container className={classes.actions}>
              <Grid item className={classes.actionLeft}>
                <Button text={<Typography variant="h4">Update APIs only</Typography>} variant="text" color="inherit" action={close} />
              </Grid>
              <Grid item>
                <Button text={<Typography variant="h4">Start Scan</Typography>} color="primary" action={startScan} />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

AskDocumentModal.propTypes = {
  startScan: PropTypes.func,
  close: PropTypes.func,
};

export default AskDocumentModal;
