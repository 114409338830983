import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Card from '../../../UI/Card/Card';
import AllCheckedInBanner from './AllCheckedInBanner/AllCheckedInBanner';
import FlightRoute from '../../../UI/FlightRoute/FlightRoute';
import Actions from './Actions/Actions';
import PassengerList from './PassengerList/PassengerList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.lightBackground.main,
  },
}));

const FlightCard = (props) => {
  const {
    journeyIndex,
    journey,
  } = props;

  const classes = useStyles();
  const [loadingBar, setLoadingBar] = useState(false);

  const flightRoute = (
    journey && journey.map((flight) => (
      <FlightRoute
        departureTimeZone={flight.station.from.timeZone}
        arrivalTimeZone={flight.station.to.timeZone}
        key={`${flight.carrier}${flight.flightNr}${flight.flightTime.scheduledDeparture}`}
        flightNumber={flight.carrier + flight.flightNr}
        scheduledDeparture={flight.flightTime.scheduledDeparture}
        scheduledArrival={flight.flightTime.scheduledArrival}
        departureStationCode={(flight.station && flight.station.from) ? flight.station.from.code : 'N/A'}
        departureStationName={(flight.station && flight.station.from) ? flight.station.from.name : 'N/A'}
        arrivalStationCode={(flight.station && flight.station.to) ? flight.station.to.code : 'N/A'}
        arrivalStationName={(flight.station && flight.station.to) ? flight.station.to.name : 'N/A'}
      />
    ))
  );

  const actions = (
    <Actions
      journeyIndex={journeyIndex}
      flights={journey}
      setLoadingBar={setLoadingBar}
    />
  );

  return (
    <Card
      loadingBar={loadingBar}
      topSection={journey.every((flight) => flight.passengers
        && flight.passengers.every((passenger) => passenger.checkin))
        ? <AllCheckedInBanner />
        : <></>}
      middleSection={(
        <Grid container>
          <Grid item xs={12} md={6} className={classes.root}>
            {flightRoute}
          </Grid>
          <Grid item xs={12} md={6}>
            <PassengerList passengers={journey[0].passengers} journey={journey[0]} />
          </Grid>
        </Grid>
      )}
      actions={actions}
    />
  );
};

FlightCard.propTypes = {
  journeyIndex: PropTypes.number,
  journey: PropTypes.array.isRequired,
};

export default FlightCard;
