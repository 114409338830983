import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import wghMuiTheme from './themes/WGH/Default';
import dxMuiTheme from './themes/DX/Default';
import dkMuiTheme from './themes/DK/Default';
import bixVingMuiTheme from './themes/BIX_VING/Default';
import bixApolloMuiTheme from './themes/BIX_APOLLO/Default';
import Layout from './hoc/Layout/Layout';
import BookingSearch from './containers/BookingSearch/BookingSearch';
import DangerousGoods from './containers/DangerousGoods/DangerousGoods';
import Checkin from './containers/Checkin/Checkin';
import BoardingPass from './containers/BoardingPass/BoardingPass';
import Summary from './containers/Summary/Summary';
import TermsAndConditions from './containers/legal/terms-and-conditions';
import PrivacyPolicy from './containers/legal/privacy-policy';
import { LanguageProvider } from './context/language';
import { NavigationProvider } from './context/navigation';
import { BookingProvider } from './context/booking';
import { CheckinProvider } from './context/checkin';
import { SeatMapProvider } from './context/seatMap';
import { BoardingPassProvider } from './context/boardingPass';
import { PassengerInfoProvider } from './context/passengerInfo';
import { ThemeContext, ThemeProvider } from './context/theme';

import * as routes from './Routes';

const ThemeSelector = (props) => {
  const { children } = props;
  const { theme } = useContext(ThemeContext);
  let muiTheme = wghMuiTheme;
  if (theme === 'DX') {
    muiTheme = dxMuiTheme;
  } else if (theme === 'BIX_VING') {
    muiTheme = bixVingMuiTheme;
  } else if (theme === 'BIX_APOLLO') {
    muiTheme = bixApolloMuiTheme;
  } else if (theme === 'DK') {
    muiTheme = dkMuiTheme;
  }
  return (
    <MuiThemeProvider theme={muiTheme}>
      {children}
    </MuiThemeProvider>
  );
};

const App = () => (
  <ThemeProvider>
    <ThemeSelector>
      <CssBaseline />
      <LanguageProvider>
        <NavigationProvider>
          <BookingProvider>
            <PassengerInfoProvider>
              <Layout>
                <Switch>
                  <Route exact path={routes.BOOKING_SEARCH}>
                    <BookingSearch />
                  </Route>
                  <Route exact path={routes.DANGEROUS_GOODS}>
                    <DangerousGoods />
                  </Route>
                  <Route exact path={routes.CHECK_IN}>
                    <CheckinProvider>
                      <SeatMapProvider>
                        <Checkin />
                      </SeatMapProvider>
                    </CheckinProvider>
                  </Route>
                  <Route exact path={routes.BOARDING_PASS}>
                    <BoardingPassProvider>
                      <BoardingPass />
                    </BoardingPassProvider>
                  </Route>
                  <Route exact path={routes.SUMMARY}>
                    <Summary />
                  </Route>
                  <Route exact path={routes.PING}>
                    <div>OK</div>
                  </Route>
                  <Route exact path={routes.TERMS_AND_CONDITIONS}>
                    <TermsAndConditions />
                  </Route>
                  <Route exact path={routes.PRIVACY_POLICY}>
                    <PrivacyPolicy />
                  </Route>
                  <Redirect to="/" />
                </Switch>
              </Layout>
            </PassengerInfoProvider>
          </BookingProvider>
        </NavigationProvider>
      </LanguageProvider>
    </ThemeSelector>
  </ThemeProvider>
);

export default App;
