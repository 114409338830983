/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import moment from 'moment';
import { Box, Dialog, DialogActions, LinearProgress } from '@material-ui/core';
import InputField from '../../../UI/InputField/InputField';
import Button from '../../../UI/Button/Button';
import Text from '../../../UI/Text/Text';
import AcceptanceControls from './AcceptanceControls/AcceptanceControls';
import DialogComponent from '../../../UI/Dialog/Dialog';
import AskDocumentModal from './AskDocumentModal/AskDocumentModal';
import AskContactModal from './AskContactModal/AskContactModal';
import ConditionModal from './ConditionModal/ConditionModal';
import Notification from '../../../UI/Notification/Notification';

// Acceptance stages
const stage = Object.freeze({
  api: 'API',
  adc: 'ADC',
  checkin: 'CHECKIN',
});

// Styles
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  title: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  inputField: {
    marginTop: theme.spacing(2),
  },
}));

// All required inputfields
const inputData = Object.freeze({
  apiBac: {
    passportNumber: 'Passport number (XXXXXXXX)',
    expiryDate: 'Expiry date (YYYYMMDD)',
    dateOfBirth: 'Date of Birth (YYYYMMDD)',
  },
  api: {
    surName: 'Surname',
    firstName: 'First name',
    nationality: 'Nationality (XXX)',
    gender: 'Gender (M/F)',
    documentType: 'Document type (P/V)',
    issuingCountry: 'Issuing country (XXX)',
  },
  apiAddress: {
    countryOfResidence: 'Country of residence (XXX)',
    destinationStreet: 'Destination street',
    destinationZipCode: 'Destination zip code',
    destinationCity: 'Destination city',
    destinationState: 'Destination state (XX)',
    destinationCountry: 'Destination country (XX)',
  },
  emergencyContact: {
    surname: 'Contact Surname',
    firstName: 'Contact First name',
    phoneNumber: 'Phone number',
    comment: 'Comment',
    countryOfResidence: 'Country of Residence (XXX)',
    carrier: 'Carrier',
  },
  document: {
    documentCode: 'Document Code',
    documentNumber: 'documentNumber',
    expiryDate: 'Expiry date (YYYYMMDD)',
  },
});

const AcceptanceForm = (props) => {
  const classes = useStyles();

  // Props
  const {
    booking, activePassenger, station, passengerAcceptance, passengers, updateAcceptance,
    open, setOpen, value, setValue, setMrzScanCamera, loadingBar, ocrScanned, setOcrScanned,
    setShowConditionsModal, showConditionsModal, setUpdateApisLoading, updateApisError, clearErrors,
    setStartDocumentScan, startDocumentScan, greencardActive, destinationActive,
  } = props;

  // State
  const [loading, setLoading] = useState(false);
  const [override, setOverride] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [askContact, setAskContact] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [declineContact, setDeclineContact] = useState(false);
  const [askDocumentScan, setAskDocumentScan] = useState(false);
  const [skipDocumentScan, setSkipDocumentScan] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [fields, setFields] = useState({
    apiBac: {},
    api: {},
    apiAddress: {},
    emergencyContact: {},
    document: {},
  });
  // Refs
  const firstRender = useRef(true);

  // Generate control state
  const handleGenerateControlState = useCallback((controls) => {
    function getDefaultFieldValue(fieldName, i) {
      const { apiInformation: apiInfo, apiAddress } = passengers[i];

      let defaultValue = '';
      switch (fieldName) {
        case 'surName':
          defaultValue = apiInfo ? apiInfo.surName : passengers[i].name.surName;
          break;
        case 'firstName':
          defaultValue = apiInfo ? apiInfo.firstName : passengers[i].name.firstName;
          break;
        case 'nationality':
          defaultValue = apiInfo && apiInfo.nationality
            ? apiInfo.nationality
            : passengers[i].nationality;
          break;
        case 'gender':
          defaultValue = apiInfo && apiInfo.gender
            ? apiInfo.gender
            : passengers[i].gender;
          break;
        case 'dateOfBirth':
          defaultValue = apiInfo ? apiInfo.dateOfBirth : passengers[i].dateOfBirth;
          break;
        case 'documentType':
          defaultValue = apiInfo ? apiInfo.documentType : null;
          break;
        case 'expiryDate':
          defaultValue = apiInfo ? apiInfo.expiryDate : null;
          break;
        case 'passportNumber':
          defaultValue = apiInfo ? apiInfo.passportNumber : null;
          break;
        case 'issuingCountry':
          defaultValue = apiInfo ? apiInfo.issuingCountry : null;
          break;

        case 'countryOfResidence':
          defaultValue = apiAddress && apiAddress.countryOfResidence ? apiAddress.countryOfResidence : null;
          break;
        case 'destinationCountry':
          defaultValue = apiAddress ? apiAddress.destinationCountry : null;
          break;
        case 'destinationState':
          defaultValue = apiAddress ? apiAddress.destinationState : null;
          break;
        case 'destinationZipCode':
          defaultValue = apiAddress ? apiAddress.destinationZipCode : null;
          break;
        case 'destinationCity':
          defaultValue = apiAddress ? apiAddress.destinationCity : null;
          break;
        case 'destinationStreet':
          defaultValue = apiAddress ? apiAddress.destinationStreet : null;
          break;
        default:
          defaultValue = '';
          break;
      }

      return defaultValue !== null ? defaultValue : '';
    }

    // Generate Controls from initial state
    const generateControls = (i) => {
      let generatedControls = {};
      Object.entries(controls).forEach((control) => {
        const defaultFieldValue = getDefaultFieldValue(control[0], i);
        generatedControls = {
          ...generatedControls,
          [control[0]]: {
            name: control[1],
            value: defaultFieldValue,
            valid: defaultFieldValue !== '',
            error: '',
          },
        };
      });

      return generatedControls;
    };

    // Create separate controls for each passenger
    return passengerAcceptance.map((passenger, i) => generateControls(i));
  }, [passengerAcceptance, passengers]);

  // Toggle address controls & trigger errors
  const handleToggleAddress = useCallback((error) => {
    setShowAddress((prevState) => !prevState);
    if (error && error.length) {
      setFields((prevState) => {
        const inputObject = {};
        Object.keys(prevState.apiAddress[activePassenger]).forEach((key) => {
          inputObject[key] = {
            ...prevState.apiAddress[activePassenger][key],
            error,
          };
        });
        return {
          ...prevState,
          apiAddress: {
            ...prevState.apiAddress,
            [activePassenger]: {
              ...inputObject,
            },
          },
        };
      });
    }
  }, [activePassenger]);

  // Handle input validation while typing
  const handleInputValidation = (fieldName, fieldValue, section) => {
    let valid = false;
    let error = '';
    if (section === 'emergencyContact') {
      switch (fieldName) {
        case 'countryOfResidence':
          valid = fieldValue.length === 3;
          break;
        case 'phoneNumber':
          valid = fieldValue.length === 10;
          break;
        default:
          valid = true;
      }
    } else {
      switch (fieldName) {
        case 'passportNumber':
        // eslint-disable-next-line
        valid = (fieldValue.length >= 6 && fieldValue.length <= 30) && fieldValue.match('[a-z\d]*');
          break;
        case 'dateOfBirth':
          valid = (fieldValue.length === 8)
          && Number.isInteger(Number(fieldValue))
          && Number(fieldValue.substring(4, 6)) <= 12
          && Number(fieldValue.substring(6, 8)) <= 31
          && (moment(fieldValue).isBefore(moment()));
          break;
        case 'expiryDate':
        // Date validation
          valid = (fieldValue.length === 8)
          && Number.isInteger(Number(fieldValue))
          && Number(fieldValue.substring(4, 6)) <= 12
          && Number(fieldValue.substring(6, 8)) <= 31
          && (moment(fieldValue).isAfter(moment()));
          break;
        case 'surName':
          valid = (passengers.length === passengerAcceptance.length ? (fieldValue.length > 0 && fieldValue.match('[A-Z]') && (fieldValue === passengers[activePassenger].name.surName)) : true);
          error = (passengers[activePassenger].name.surName === fieldValue) || !passengers[activePassenger].name.surName.length ? '' : `Please check the spelling of last name ${passengers[activePassenger].name.surName}`;
          break;
        case 'firstName':
          valid = (passengers.length === passengerAcceptance.length ? (fieldValue.length > 0 && fieldValue.match('[A-Z]') && (fieldValue === passengers[activePassenger].name.firstName)) : true);
          error = (passengers[activePassenger].name.firstName === fieldValue) || !passengers[activePassenger].name.firstName.length ? '' : `Please check the spelling of first name ${passengers[activePassenger].name.firstName}`;
          break;
        case 'nationality':
        case 'issuingCountry':
          valid = fieldValue.length === 3 && fieldValue.match('^[a-zA-Z()]+$');
          break;
        case 'gender':
          valid = fieldValue === 'M' || fieldValue === 'F';
          break;
        case 'documentType':
          valid = fieldValue === 'P' || fieldValue === 'V' || fieldValue === 'PV';
          break;
        case 'countryOfResidence':
          valid = fieldValue.length === 3;
          break;
        case 'destinationState':
          valid = fieldValue.length > 1 && fieldValue.match('^[a-zA-Z()]+$');
          break;
        case 'destinationCountry':
          valid = fieldValue.length === 2;
          break;
        case 'destinationStreet':
          valid = fieldValue.length > 0;
          break;
        case 'destinationZipCode':
          valid = (fieldValue.length === 5)
        && Number.isInteger(Number(fieldValue));
          break;
        case 'destinationCity':
          valid = fieldValue.length > 0;
          break;
        default:
          valid = true;
      }
    }
    return { valid, error };
  };

  // Mount
  useEffect(() => {
    if (firstRender.current) {
      setFields({
        apiBac: handleGenerateControlState(inputData.apiBac),
        api: handleGenerateControlState(inputData.api),
        apiAddress: handleGenerateControlState(inputData.apiAddress),
        emergencyContact: handleGenerateControlState(inputData.emergencyContact),
        document: handleGenerateControlState(inputData.document),
      });
      firstRender.current = false;
    }
  }, [handleGenerateControlState, firstRender]);

  // Handle input changes
  const handleChangeInput = (name, section) => (event) => {
    // Handle input validation
    const { valid, error } = handleInputValidation(name, event.target.value.toUpperCase(), section);
    const triggerOcrChangeValues = ['passportNumber', 'dateOfBirth', 'expiryDate', 'surName', 'firstName', 'nationality', 'gender', 'documentType'];
    if (ocrScanned && triggerOcrChangeValues.includes(name)) {
      setOcrScanned(false);
    }
    // Handle change input values and validation
    setFields({
      ...fields,
      [section]: {
        ...fields[section],
        [activePassenger]: {
          ...fields[section][activePassenger],
          [name]: {
            ...fields[section][activePassenger][name],
            value: event.target.value.toUpperCase(),
            valid,
            error: valid ? '' : (error || 'Incomplete or invalid data provided, please correct'),
          },
        },
      },
    });
  };

  // Generate inputfields
  const handleGenerateInputFields = (section) => {
    // Create inputElements for each passenger
    const inputElements = Object.keys(inputData[section]).map((key) => ({
      id: key,
      ...fields[section][activePassenger][key],
    }));

    const inputField = (input) => (
      <Grid key={input.id} className={classes.inputField}>
        <InputField
          type="text"
          fullWidth
          label={input.name}
          id={input.id}
          onChange={handleChangeInput(input.id, section)}
          value={input.value}
          error={!input.valid}
          helperText={input.valid ? '' : input.error}
        />
      </Grid>
    );

    // Return inputfields based on the inputelements
    return inputElements.map((input) => inputField(input));
  };

  // Autofill inputfields
  useEffect(() => {
    if (value && !startDocumentScan) {
      setMrzScanCamera(false);
      const scan = JSON.parse(value);
      setValue(null);
      const { details } = scan;

      setFields((prevState) => {
        const expirationDateCheck = moment(scan.fields.expirationDate, 'YYMMDD').format('20YYMMDD');
        let birthDateCheck = moment(scan.fields.birthDate, 'YYMMDD').format('YYYYMMDD');
        if ((moment(birthDateCheck).isAfter(moment(expirationDateCheck)))) {
          if (expirationDateCheck < birthDateCheck) {
            birthDateCheck = moment(scan.fields.birthDate, 'YYMMDD').format('19YYMMDD');
          }
        }
        return {
          ...prevState,
          api: {
            ...prevState.api,
            [activePassenger]: {
              ...prevState.api[activePassenger],
              surName: {
                ...prevState.api[activePassenger].surName,
                value: scan.fields.lastName,
                // eslint-disable-next-line max-len
                valid: (passengers[activePassenger].name.surName === scan.fields.lastName) || !scan.fields.lastName.length,
                error: (passengers[activePassenger].name.surName === scan.fields.lastName) || !scan.fields.lastName.length ? '' : `Please check the spelling of last name ${passengers[activePassenger].name.surName}`,
              },
              firstName: {
                ...prevState.api[activePassenger].firstName,
                value: scan.fields.firstName,
                // eslint-disable-next-line max-len
                valid: (passengers[activePassenger].name.firstName === scan.fields.firstName) || !scan.fields.firstName.length,
                error: (passengers[activePassenger].name.firstName === scan.fields.firstName) || !scan.fields.firstName.length ? '' : `Please check the spelling of first name ${passengers[activePassenger].name.firstName}`,
              },
              nationality: {
                ...prevState.api[activePassenger].nationality,
                value: scan.fields.nationality,
                valid: true,
                error: '',
              },
              gender: {
                ...prevState.api[activePassenger].gender,
                value: details[9].ranges[0].raw,
                valid: details[9].ranges[0].raw === 'M' || details[9].ranges[0].raw === 'F',
                error: details[9].ranges[0].raw === 'M' || details[9].ranges[0].raw === 'F' ? '' : 'Gender is incorrect, please check',
              },
              documentType: {
                ...prevState.api[activePassenger].documentType,
                value: scan.fields.documentCode,
                valid: scan.fields.documentCode === 'P' || scan.fields.documentCode === 'V' || scan.fields.documentCode === 'PV',
                error: scan.fields.documentCode === 'P' || scan.fields.documentCode === 'V' || scan.fields.documentCode === 'PV' ? '' : 'Document type is incorrect, please check',
              },
              issuingCountry: {
                ...prevState.api[activePassenger].issuingCountry,
                value: scan.fields.issuingState,
                valid: true,
                error: '',
              },
              isOcr: ocrScanned,
            },
          },
          apiBac: {
            ...prevState.apiBac,
            [activePassenger]: {
              ...prevState.apiBac[activePassenger],
              passportNumber: {
                ...prevState.apiBac[activePassenger].passportNumber,
                value: scan.fields.documentNumber,
                valid: true,
                error: '',
              },
              expiryDate: {
                ...prevState.apiBac[activePassenger].expiryDate,
                value: expirationDateCheck,
                valid: expirationDateCheck.length === 8,
                error: (moment(birthDateCheck).isBefore(moment())) && (moment(expirationDateCheck).isAfter(moment())) ? '' : 'Expiry date is not valid, please check',
              },
              dateOfBirth: {
                ...prevState.apiBac[activePassenger].dateOfBirth,
                value: birthDateCheck,
                valid: birthDateCheck.length === 8,
                error: (moment(birthDateCheck).isBefore(moment())) ? '' : 'Expiry date or date of birth is incorrect, please check',
              },
            },
          },
        };
      });
    }
  }, [activePassenger, passengers, startDocumentScan, value]);

  useEffect(() => {
    if (value && startDocumentScan) {
      setShowDocument(true);
      setStartDocumentScan(false);
      const scan = JSON.parse(value);
      setValue(null);
      setFields((prevState) => ({
        ...prevState,
        document: {
          ...prevState.document,
          [activePassenger]: {
            ...prevState.document[activePassenger],
            documentNumber: {
              ...prevState.document[activePassenger].documentNumber,
              value: scan.fields.documentNumber ? scan.fields.documentNumber : scan.fields.travelerDocumentId,
              valid: true,
              error: '',
            },
            expiryDate: {
              ...prevState.document[activePassenger].expiryDate,
              value: scan.fields.expirationDate ? scan.fields.expirationDate : scan.fields.documentExpiration,
              error: '',
            },
            documentCode: {
              ...prevState.document[activePassenger].documentCode,
              value: scan.fields.documentCode,
              error: '',
            },
          },
        },
      }));
    }
  }, [activePassenger, value, startDocumentScan]);

  // Create request object for a section
  const handleCreateRequestObject = (section) => {
    const inputObject = {};
    Object.keys(fields[section][activePassenger]).forEach((key) => {
      if (key !== 'isOcr') {
        inputObject[key] = fields[section][activePassenger][key].value;
      } else {
        inputObject[key] = ocrScanned;
      }
    });
    return inputObject;
  };

  useEffect(() => {
    if (passengerAcceptance
        && passengerAcceptance[activePassenger]
        && passengerAcceptance[activePassenger].warnings.some(
          (warning) => warning.type === stage.api && warning.done,
        )
        && passengerAcceptance[activePassenger].warnings.some(
          (warning) => warning.type === stage.adc && !warning.done,
        )) {
      setShowConditionsModal(true);
    }
  }, [passengerAcceptance, activePassenger, setShowConditionsModal]);

  // Update acceptance
  const handleUpdateAcceptance = (request, type) => {
    updateAcceptance(request, type);
    // setLoading(true);
    setShowError(false);
  };

  // Update API
  // kolla här imon så att flödet fungerar som planerat
  const handleUpdateAPI = () => {
    if (
      !showContact
      && (station.from.usAirport || station.to.usAirport)
    ) {
      setAskContact(true);
      return;
    }

    if (!showDocument && (station.from.usAirport || station.to.usAirport) && (fields.apiAddress[activePassenger].countryOfResidence.value === 'USA' || fields.apiAddress[activePassenger].countryOfResidence.value === 'US') && !(fields.api[activePassenger].nationality.value === 'USA' || fields.api[activePassenger].nationality.value === 'US')) {
      setShowDocument(true);
      return;
    }

    const apiRequestObject = {
      apiInformation: { ...handleCreateRequestObject('apiBac'), ...handleCreateRequestObject('api') },
      apiAddress: null,
      emergencyContact: null,
      regulatoryCountry: booking.station.to.country,
      document: null,
    };
    if (showAddress) {
      apiRequestObject.apiAddress = handleCreateRequestObject('apiAddress');
    }
    if (showDocument) {
      apiRequestObject.document = handleCreateRequestObject('document');
    }
    if (showContact) {
      apiRequestObject.emergencyContact = handleCreateRequestObject('emergencyContact');
    }

    const request = {
      apiRequestObject,
    };

    handleUpdateAcceptance(request, stage.api);
  };

  // Show api section
  let sectionApi;
  if (Object.entries(fields.api).length) {
    sectionApi = (
      <Box>
        <Paper
          style={{
            padding: 16,
            marginBottom: '25px',
            backgroundColor: '#DDF0DE',
            color: '#369e3b',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: '0px 2px 4px rgba(255, 255, 255, 0.5)',
          }}
        >
          <Typography variant="body1">{passengers[activePassenger].name.fullName}</Typography>
        </Paper>
        {handleGenerateInputFields('apiBac')}
        <Grid key="separator" className={classes.inputField}>
          <Divider />
        </Grid>
        {handleGenerateInputFields('api')}
      </Box>
    );
  }
  // Check input validation
  const handleCheckInputValidation = (field, active) => {
    const validFields = [];
    if (active >= 0 && field) {
      Object.keys(field[active]).forEach((key) => {
        if (key !== 'isOcr') {
          if (field[active][key].valid) {
            validFields.push(true);
          } else {
            validFields.push(false);
          }
        } else {
          validFields.push(true);
        }
      });
    }
    return validFields;
  };

  // Show Emergency Contact Fields
  const handleShowContact = () => {
    setShowContact(true);
    setAskContact(false);
  };

  // Skip Emergency Contact Fields
  const handleSkipContact = () => {
    // setDeclineContact(true);
    setAskContact(false);
  };

  // Show Regulatory Document Fields
  const handleStartRegulatoryDocumentScan = () => {
    setStartDocumentScan(true);
    setMrzScanCamera(true);
    setAskDocumentScan(false);
  };

  // Skip Regulatory Document Scan
  const handleSkipRegulatoryDocumentScan = () => {
    setSkipDocumentScan(true);
    setAskDocumentScan(false);
  };

  useEffect(() => {
    if (greencardActive) {
      setShowDocument((prevState) => !prevState);
    }
    if (destinationActive) {
      setShowAddress((prevState) => !prevState);
    }
  }, []);

  let validFields = [];
  // Check if fields are valid and if showAddress is enabled
  if (Object.keys(fields.api).length > 0) {
    validFields = handleCheckInputValidation(fields.api, activePassenger);
    validFields = validFields.concat(handleCheckInputValidation(fields.apiBac, activePassenger));
    if (showAddress) {
      validFields = validFields.concat(
        handleCheckInputValidation(fields.apiAddress, activePassenger),
      );
    }
    if (showDocument) {
      validFields = validFields.concat(
        handleCheckInputValidation(fields.document, activePassenger),
      );
    }
  }

  // Show apiAddress section
  let sectionApiAddress;
  if (showAddress) {
    sectionApiAddress = (
      <Box>
        <Grid key="separator" className={classes.inputField}>
          <Divider />
        </Grid>
        <Typography className={classes.title}>
          <Text id="greencard-address" />
        </Typography>
        {handleGenerateInputFields('apiAddress')}
      </Box>
    );
  }

  // Show apiAddress section
  let sectionDocument;
  if (showDocument) {
    sectionDocument = (
      <Box>
        <Grid key="separator" className={classes.inputField}>
          <Divider />
        </Grid>
        <Typography className={classes.title}>
          <Text id="greencard-required" />
        </Typography>
        {handleGenerateInputFields('document')}
      </Box>
    );
  }

  let sectionEmergencyContact;
  if (showContact) {
    sectionEmergencyContact = (
      <Box>
        <Grid key="separator" className={classes.inputField}>
          <Divider />
        </Grid>
        <Typography className={classes.title}>
          <Text id="emergency-contact-details" />
        </Typography>
        {handleGenerateInputFields('emergencyContact')}
      </Box>
    );
  }

  // Ask Document Modal
  let askDocumentModal;
  if (askDocumentScan) {
    askDocumentModal = (
      <AskDocumentModal
        startScan={handleStartRegulatoryDocumentScan}
        close={handleSkipRegulatoryDocumentScan}
      />
    );
  }

  // Show controls
  const controls = (
    <AcceptanceControls
      toggleName="Show address"
      show={showAddress}
      toggle={handleToggleAddress}
      buttonName="Update APIs"
      action={handleUpdateAPI}
      disabled={!validFields.every((element) => element)}
      loading={loading}
    />
  );

  // Ask Contact Modal
  let askContactModal;
  if (askContact) {
    askContactModal = (
      <AskContactModal
        showContact={handleShowContact}
        close={handleSkipContact}
      />
    );
  }

  const handleUpdateADC = () => {
    const adcRequestObject = {
      documentType: fields.api[activePassenger].documentType.value,
      surName: fields.api[activePassenger].surName.value,
      override,
    };
    const request = {
      adcRequestObject,
    };
    handleUpdateAcceptance(request, stage.adc);
  };
  // Show condition modal
  let conditionModal;

  if (showConditionsModal) {
    conditionModal = (
      <ConditionModal
        close={setShowConditionsModal}
          // conditions={acceptance.data.adcMessage}
        updateADC={handleUpdateADC}
        loading={loading}
      />
    );
  }

  // Error
  useEffect(() => {
    if (updateApisError) {
      setUpdateApisLoading(false);
      setShowError(true);
    }
  }, [updateApisError]);

  // Show error message
  let errorMessage;
  if (showError && updateApisError) {
    errorMessage = (
      <Notification onClose={clearErrors} severity="error" message={<Text id="check-in-error" />} />
    );
  }

  return (
    <>
      <DialogComponent
        open={open}
        setOpen={setOpen}
        action={null}
      >
        {conditionModal}
        {askContactModal}
        {askDocumentModal}
        {sectionApi}
        {sectionApiAddress}
        {sectionEmergencyContact}
        {sectionDocument}
        {controls}
        {loadingBar && (
        <Box className={classes.root}>
          <LinearProgress />
        </Box>
        )}
        <DialogActions>
          <Box m={1} style={{ width: '100%' }}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Button
                  text={<Typography variant="h4"><Text id="footer-back-button" /></Typography>}
                  action={() => setOpen(false)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <Button
                  className={classes.actionCheckInButtons}
                  fullWidth
                  color="secondary"
                  action={() => handleUpdateAPI()}
                  text={<Typography color="textSecondary" variant="caption"><Text id="update-passport-information" /></Typography>}
                  disabled={!validFields.every((element) => element)}
                />
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </DialogComponent>
    </>
  );
};

AcceptanceForm.propTypes = {
  activePassenger: PropTypes.number,
  passengerAcceptance: PropTypes.array,
  updateAcceptance: PropTypes.func,
  station: PropTypes.object,
};

export default AcceptanceForm;
