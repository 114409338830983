const colors = {
  background: {
    main: '#F9F9F9',
  },
  lightBackground: {
    main: '#F5BBA4',
  },
  primary: {
    main: '#FE8100',
  },
  secondary: {
    main: '#FE8100',
  },
  error: {
    main: '#FE0009',
  },
  success: {
    main: '#028E59',
  },
  text: {
    primary: '#333333',
    secondary: '#FFF',
  },
  warning: {
    main: '#F9B41E',
  },
  input: {
    background: '#FFF',
    label: '#333333',
    border: '#333333',
  },
  alternative: {
    contrast: '#EFEFEF',
  },
  stepper: {
    icon: '#FFF',
    line: '#FFF',
    text: '#FFF',
  },
};

export default colors;
