import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import thunkMiddleware from 'redux-thunk';
// import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';

import { combineReducers, applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createMiddleware } from 'react-action-listener';
import packageJson from '../package.json';
import App from './App';

// Redux imports
import featuresReducer from './store/reducers/features/features';

if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
  Sentry.init({
    environment: process.env.REACT_APP_DEPLOY_ENV || 'test',
    release: `${packageJson.name}@${packageJson.version}`,
    dsn: 'https://d053e4d99748439495cb1131a2a01516@o172762.ingest.sentry.io/4504077315211264',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

// Combine reducers
const rootReducer = combineReducers({
  features: featuresReducer,
});

// Middleware
const loggerMiddleware = createLogger({ collapsed: true });

let middleware = applyMiddleware(
  createMiddleware(),
  thunkMiddleware, // Lets us dispatch in store
  loggerMiddleware, // Neat middleware that logs actions
);

if (process.env.NODE_ENV === 'production') {
  middleware = applyMiddleware(
    createMiddleware(),
    thunkMiddleware,
  );
}

// Store
export const store = createStore(
  rootReducer,
  composeWithDevTools(middleware),
);

const startApp = () => {
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root'),
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}

// Service worker
// if (process.env.REACT_APP_DEPLOY_ENV === 'production') {
//   serviceWorker.register();
// } else {
//   serviceWorker.unregister();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
