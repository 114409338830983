/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CryptoJS from 'crypto-js';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { NavigationContext } from '../../context/navigation';
import { BookingContext } from '../../context/booking';
import SearchForm from '../../components/BookingSearch/SearchForm';
import Loading from '../../components/UI/Loading/Loading';
import Text from '../../components/UI/Text/Text';
import * as routes from '../../Routes';
import Notification from '../../components/UI/Notification/Notification';

import Background from '../../assets/img/backgroundNew.jpg';
import { ReactComponent as WGHLogo } from '../../assets/img/logoWGHColor.svg';
import { ReactComponent as DXLogo } from '../../assets/img/DX/logoDXColor.svg';
import { ReactComponent as DKLogo } from '../../assets/img/DK/logoDKColor.svg';
import { ReactComponent as BIXVINGLogo } from '../../assets/img/BIX_VING/logoBIXColor.svg';
import { ReactComponent as BIXAPOLLOLogo } from '../../assets/img/BIX_APOLLO/logoBIXColor.svg';
import { wwwDir } from '../../utils/cordovaGlobals';
import { checkCarrierWhitelist } from '../../utils/index';
import { ThemeContext } from '../../context/theme';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 'auto',
    maxWidth: '60vw',
    marginRight: '20px',
    width: '150px',
    [theme.breakpoints.up('xl')]: {
      width: '150px',
    },
  },
  backgroundMobil: {
    backgroundImage: `url(${wwwDir}${Background})`,
    backgroundPosition: 'left',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      backgroundImage: 'none',
    },
  },
  backgroundDesktop: {
    height: '100vh',
    maxWidth: '100%',
    float: 'left',
    objectFit: 'cover',
    objectPosition: 'left',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const BookingSearch = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const {
    setStep,
    setAppEmbedded,
  } = useContext(NavigationContext);

  const {
    setTheme,
    theme,
  } = useContext(ThemeContext);

  const {
    booking,
    bookingError,
    setBookingError,
    fetchBookingByPNRAndName,
    fetchBookingByTicketAndName,
    clearBooking,
    setPassengerIdentifier,
    setAutoCheckin,
  } = useContext(BookingContext);

  useEffect(() => {
    setStep(-1);
  }, [setStep]);

  useEffect(() => {
    if (booking && loading) {
      const response = checkCarrierWhitelist(booking, query.get('allCarriers'));
      if (response && response.type === 'error') {
        setBookingError(response);
        history.push('/');
      } else if (query.get('autoCheckin')) {
        history.push(routes.CHECK_IN);
      } else { history.push(routes.DANGEROUS_GOODS); }
    } else {
      clearBooking();
    }
  }, [booking, loading, clearBooking, history, setBookingError, query]);

  useEffect(() => {
    if (bookingError) {
      setLoading(false);
    }
  }, [bookingError, setLoading]);

  useEffect(() => {
    if (query.get('carrier')) {
      setTheme(query.get('carrier'));
      document.title = `${query.get('carrier')} checkin`;
    }
    if (query.get('pi')) {
      setPassengerIdentifier(query.get('pi'));
    }
    if (Number(query.get('AppEmbedded')) === 1) {
      setAppEmbedded(true);
    }
    if (query.get('autoCheckin')) {
      setAutoCheckin(query.get('autoCheckin'));
      if (query.get('pnr') && query.get('lastName')) {
        setLoading(true);
        fetchBookingByPNRAndName(query.get('pnr').toUpperCase(), query.get('lastName').toUpperCase());
      }
    } else if (query.get('pnr') && query.get('lastName')) {
      setLoading(true);
      fetchBookingByPNRAndName(query.get('pnr').toUpperCase(), query.get('lastName').toUpperCase());
    }
    if (query.get('data')) {
      const decodeUrlTemp = decodeURIComponent(query.get('data'));
      // Remove old key in future (bestKey)
      const decryptUrlGHA = CryptoJS.AES.decrypt(decodeUrlTemp, 'bestKey');
      let completed = false;
      try {
        const decryptedTextGHA = decryptUrlGHA.toString(CryptoJS.enc.Utf8);
        if (decryptedTextGHA && decryptedTextGHA.startsWith('autoCheckin')) {
          completed = true;
          history.push(`/?${decryptedTextGHA}&allCarriers=Y`);
          document.location.reload();
        }
      } catch {
        console.log('Something wrong with the url: old key');
      }
      try {
        if (!completed) {
          const parsedBase64Key = CryptoJS.enc.Base64.parse('aXRzaG91bGRiZTE2Y2hhcg==');
          const decryptedData = CryptoJS.AES.decrypt(
            decodeUrlTemp,
            parsedBase64Key,
            {
              mode: CryptoJS.mode.ECB,
              padding: CryptoJS.pad.Pkcs7,
            },
          );
          const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
          history.push(`/?${decryptedText}&allCarriers=Y`);
          document.location.reload();
        }
      } catch {
        console.log('Something wrong with the url: new key');
      }
    }
  }, []);

  const handleSubmitPNR = (pnr, surname) => {
    setLoading(true);
    fetchBookingByPNRAndName(pnr.trim(), surname.trim());
  };

  const handleSubmitTicket = (ticket, surname) => {
    let tempTicket = ticket;
    tempTicket = tempTicket.replace('-', '');
    if (tempTicket.length === 15) {
      tempTicket = tempTicket.slice(0, -2);
    }
    setLoading(true);
    fetchBookingByTicketAndName(tempTicket.trim(), surname.trim());
  };

  let errorNotification;
  if (bookingError) {
    errorNotification = <Notification severity="error" message={<Text id="booking-search-error" />} />;
    if (bookingError.message === 'whitelist-error') {
      errorNotification = <Notification severity="error" message={<Text id="whitelist-error" />} />;
    }
  }

  let themeLogo = <WGHLogo alt="Logo WGH" className={classes.logo} />;
  if (theme === 'DX') {
    themeLogo = <DXLogo alt="Logo DX" className={classes.logo} />;
  } else if (theme === 'DK') {
    themeLogo = <DKLogo alt="Logo DK" className={classes.logo} />;
  } else if (theme === 'BIX_VING') {
    themeLogo = <BIXVINGLogo alt="Logo BIX" className={classes.logo} />;
  } else if (theme === 'BIX_APOLLO') {
    themeLogo = <BIXAPOLLOLogo alt="Logo BIX" className={classes.logo} />;
  }

  const logo = (window.innerHeight > 600) && (
  <Box position="absolute" style={{ bottom: '60px' }} right={0} zIndex={45}>
    {themeLogo}
  </Box>
  );

  return (
    loading ? (
      <Loading />
    ) : (
      <>
        <Box height="100vh" width="100vw" position="absolute" top={0} left={0} className={classes.backgroundMobil}>
          <Grid container style={{ height: '100%' }}>
            <Grid item lg={7} style={{ height: '100%' }}>
              <img className={classes.backgroundDesktop} src={`${wwwDir}${Background}`} alt="background" />
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box mt="20vh" position="relative" zIndex={101}>
                <Container maxWidth="xs">
                  <Paper square>
                    <Box p={2}>
                      <Box pt={3}>
                        <Typography variant="h1"><Text id="booking-search-title" /></Typography>
                      </Box>
                      <Box>
                        <Typography><Text id="booking-search-description" /></Typography>
                      </Box>
                      <SearchForm
                        handleSubmitPNR={handleSubmitPNR}
                        handleSubmitTicket={handleSubmitTicket}
                      />
                      {errorNotification}
                    </Box>
                  </Paper>
                </Container>
              </Box>
            </Grid>
          </Grid>
          {logo}
        </Box>
      </>
    )
  );
};

export default BookingSearch;
