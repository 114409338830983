import React from 'react';

import en from './en.json';

import { ReactComponent as FlagEN } from '../assets/img/flags/flagEN.svg';

export const dictionaryList = { en };

export const languages = [
  {
    text: 'English',
    value: 'en',
    icon: <FlagEN />,
  },
];
