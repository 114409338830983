const config = {
  test: {
    HEADER_LABEL: 'test',
    ENVIRONMENT: 'WGH',
  },
  prod: {
    HEADER_LABEL: '',
    ENVIRONMENT: 'WGH',
  },
  default: {
    HEADER_LABEL: 'test',
    ENVIRONMENT: 'WGH',
  },
};
exports.get = function get(env) {
  return config[env] || config.default;
};
