/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Paper, Typography } from '@material-ui/core';
import CheckedInIcon from '@material-ui/icons/CheckCircleOutline';

const SelectedPassenger = (props) => {
  const {
    passengers,
    setSelectedPassenger,
    selectedPassenger,
    noMatchFound,
    handleApisForm,
  } = props;

  const handleTagClick = (index) => {
    const selectedPassengerData = passengers[index];
    if (!selectedPassengerData.acceptance.apiDataRegistered) {
      if (selectedPassenger === index) {
        setSelectedPassenger(null); // Unselect the passenger if it is already selected
      } else {
        setSelectedPassenger(index);
      }
    }
  };

  useEffect(() => {
    if (noMatchFound) {
      handleApisForm();
    }
  }, [selectedPassenger]);

  return (
    <>
      {passengers?.length > 0
        && passengers.map((passenger, index) => (
          <Paper
            key={passenger.name.fullName}
            onClick={() => handleTagClick(index)}
            style={{
              padding: 16,
              marginTop: '8px',
              marginBottom: '8px',
              backgroundColor: passenger.acceptance.apiDataRegistered ? '#DDF0DE' : '#fff',
              color: selectedPassenger === index ? '#369e3b' : '#369e3b',
              cursor: passenger.acceptance.apiDataRegistered ? 'auto' : 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: selectedPassenger === index ? '2px solid #369e3b' : 'none',
            }}
          >
            <Typography variant="body1">{passenger.name.fullName}</Typography>
            {passenger.checkin && <CheckedInIcon />}
          </Paper>
        ))}
    </>
  );
};

export default SelectedPassenger;
