/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogActions, DialogContent, Grid, IconButton, LinearProgress, Typography, useMediaQuery } from '@material-ui/core';
import ButtonComponent from '@material-ui/core/Button';
import { MrzScanner } from '@objective/react-mrz-scanner';
import InfoIcon from '@material-ui/icons/Info';
import { Divider } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { first, update } from 'lodash';
import Button from '../../../UI/Button/Button';
import Header from '../../../../hoc/Header/Header';
import Text from '../../../UI/Text/Text';
import { CheckinContext } from '../../../../context/checkin';
import { BookingContext } from '../../../../context/booking';
import SelectedPassenger from '../SelectedPassenger/SelectedPassenger';
import AcceptanceForm from '../AcceptanceForm/AcceptanceForm';
import DialogComponent from '../../../UI/Dialog/Dialog';
import { ReactComponent as CameraIcon } from '../../../../assets/img/passportCamera.svg';
import { ReactComponent as FormIcon } from '../../../../assets/img/passportForm.svg';
import Passport from '../../../../assets/img/scanpassport.png';
import AcceptanceHelpInfo from '../AcceptanceHelpInfo/AcceptanceHelpInfo';
import { wwwDir } from '../../../../utils/cordovaGlobals';
import * as routes from '../../../../Routes';
import Notification from '../../../UI/Notification/Notification';
import ErrorMessage from '../../../UI/ErrorMessage/ErrorMessage';
import Navigation from '../../../../hoc/Navigation/Navigation';

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: 'calc(100vh - 141px)',
    height: '80vh',
    maxWidth: 600,
    margin: '0 auto',
  },
  scanTitle: {
    fontSize: 34,
    [theme.breakpoints.only('xs')]: {
      fontSize: 20,
    },
  },
  scanText: {
    fontSize: 14,
    textTransform: 'uppercase',
    [theme.breakpoints.only('xs')]: {
      paddingTop: 7,
    },
  },
  mainTestContainer: {
    margin: 16,
  },
  imageBox: {
    color: '#369e3b',
    textAlign: 'center',
    border: 'solid 3px #369e3b',
    padding: 16,
    margin: 16,
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      paddingTop: 7,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
  disabled: {
    color: '#e2e2e2',
    textAlign: 'center',
    border: 'solid 3px #e2e2e2',
    filter: 'grayscale(100%)',
    padding: 16,
    margin: 16,
    [theme.breakpoints.only('xs')]: {
      padding: 0,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  divider: {
    fontSize: 12,
    paddingTop: 10,
    paddingBottom: 10,
    padding: 40,
  },
  selectWarning: {
    fontSize: 16,
    color: '#FE0009',
    textAlign: 'center',
  },
  icon: {
    color: 'white',
    fontSize: 32,
  },
  completetCount: {
    textAlign: 'center',
    [theme.breakpoints.only('xs')]: {
      fontSize: 20,
    },
  },
  passportImage: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 5000,
    width: '100%',
    backgroundColor: 'white',
    height: '30%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    marginTop: 10,
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonClose: {
    color: 'black',
  },
  root: {
    borderRadius: '3px',
    paddingLeft: 16,
    paddingRight: 16,
  },
  scanImage: {
    paddingTop: 10,
    width: 'auto',
    height: '100%',
    itemAlign: 'center',
    objectFit: 'contain',
  },
  buttonContainer: {
    paddingBottom: 20,
  },
}));

const AcceptanceModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    passengers, flight, open, setOpen, journeyIndex,
  } = props;

  const [value, setValue] = useState();
  const [ocrScanned, setOcrScanned] = useState(false);
  const [mrzScanCamera, setMrzScanCamera] = useState(false);
  const [apisForm, setApisForm] = useState(false);
  const [selectedPassenger, setSelectedPassenger] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [selectWarningText, setSelectWarningText] = useState(false);
  const [noMatchFound, setNoMatchFound] = useState(false);
  const [showError, setShowError] = useState(false);
  const [updateApisLoading, setUpdateApisLoading] = useState(false);
  const [apisCheckinLoading, setApisCheckinLoading] = useState(false);
  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [startDocumentScan, setStartDocumentScan] = useState(false);
  const [greencard, setGreencard] = useState(false);
  const [greencardActive, setGreencardActive] = useState(false);
  const [destinationActive, setDestinationActive] = useState(false);
  const isXpScreen = useMediaQuery('(max-width: 959px)');

  const {
    checkin,
    updateAPI,
    updateADC,
    checkinFlightError,
    clearErrors,
    updateApisError,
  } = useContext(CheckinContext);

  const {
    booking,
    refetchBooking,
  } = useContext(BookingContext);

  useEffect(() => {
    clearErrors();
  }, []);

  const getPassengerIndexFromScan = (obj) => {
    const scan = JSON.parse(obj);

    const firstNameDetail = scan.details.find((detail) => detail.field === 'firstName');
    const lastNameDetail = scan.details.find((detail) => detail.field === 'lastName');

    const firstName = firstNameDetail ? firstNameDetail.value : '';
    const lastName = lastNameDetail ? lastNameDetail.value : '';

    const foundIndex = passengers?.findIndex(
      (passenger) => passenger?.name?.firstName?.toLowerCase() === firstName?.toLowerCase()
        && passenger?.name?.surName?.toLowerCase() === lastName?.toLowerCase(),
    );

    return foundIndex;
  };

  const mrzScanResult = (nextState) => {
    const passengerIndex = getPassengerIndexFromScan(nextState);
    setOcrScanned(true);
    if (passengerIndex !== -1 && selectedPassenger === null) {
      setSelectedPassenger(passengerIndex);
      setValue(nextState);
      setApisForm(true);
    } else if (selectedPassenger !== null) {
      setValue(nextState);
      setApisForm(true);
    } else {
      setValue(nextState);
      setNoMatchFound(true);
    }
    setMrzScanCamera(false);
  };

  const handelCheckin = () => {
    setApisCheckinLoading(true);
    checkin(journeyIndex).then(() => {
      refetchBooking().then(() => {
        setApisCheckinLoading(false);
      });
    });
  };

  const scanClose = () => {
    setMrzScanCamera(false);
  };

  const viewport = {
    width: 900,
    height: 160,
  };

  const viewportFor3LinesMrz = {
    width: 900,
    height: 250,
  };

  useEffect(() => {
    setSelectWarningText(false);
  }, [selectedPassenger]);

  const handleUpdateAPI = (request) => {
    const flightDetails = [{
      carrier: flight.carrier,
      flightNr: flight.flightNr,
      flightSuffix: flight.flightSuffix,
      scheduledDeparture: flight.flightTime.scheduledDeparture,
      departureStation: flight.station.from.code,
      arrivalStation: flight.station.to.code,
      passengerFlightIdentifier: request.passengerFlightIdentifier,
    }];

    const requestData = {
      ...request,
      flightDetails,
      station: flight.station.from.code,
    };

    setUpdateApisLoading(true);
    updateAPI(requestData).then((res) => {
      refetchBooking().then(() => {
        setUpdateApisLoading(false);
        setSelectedPassenger(null);
        setApisForm(false);
      });
    });
  };

  useEffect(() => {
    if (!apisForm) {
      setSelectedPassenger(null);
    }
  }, [apisForm]);

  const handleUpdateADC = (request) => {
    const flights = [{
      carrier: flight.carrier,
      flightNr: flight.flightNr,
      scheduledDeparture: flight.flightTime.scheduledDeparture,
      departureStation: flight.station.from.code,
      arrivalStation: flight.station.to.code,
      passengers: [{
        ...request,
      }],
    }];

    const adcRequestData = {
      ...request,
      flights,
      station: flight.station.from.code,
    };

    setUpdateApisLoading(true);
    updateADC(adcRequestData).then(() => {
      refetchBooking().then(() => {
        setUpdateApisLoading(false);
        setShowConditionsModal(false);
      });
    });
  };

  // Update acceptance
  const handleUpdateAcceptance = (updateData, type) => {
    // Handle acceptance for active passenger
    const passenger = passengers[selectedPassenger];
    // Request
    const requestDataAPI = {
      ...updateData.apiRequestObject,
      passengerIdentifier: passenger.passengerIdentifier,
      passengerFlightIdentifier: passenger.passengerFlightIdentifier,
      pnr: passenger.pnr,
      type: passenger.type,
    };

    const requestDataADC = {
      ...updateData.adcRequestObject,
      passengerIdentifier: passenger.passengerIdentifier,
      passengerFlightIdentifier: passenger.passengerFlightIdentifier,
      pnr: passenger.pnr,
      type: passenger.type,
    };

    // Check if stage is done, then update adc/api
    if (type === 'ADC') {
      handleUpdateADC(requestDataADC);
    } else if (type === 'API') {
      handleUpdateAPI(requestDataAPI);
    }
  };

  const handleCameraScan = () => {
    if (!passengers.every((passenger) => passenger.acceptance.apiDataRegistered)) {
      setMrzScanCamera(true);
    }
  };

  const handleApisForm = () => {
    if (selectedPassenger !== null) {
      setApisForm(true);
    } else if (noMatchFound === false) {
      setSelectWarningText(true);
    }
  };

  // Toggle viewpoint for mrz scanner
  useEffect(() => {
    if (mrzScanCamera) {
      const viewport = document.getElementsByName('viewport')[0];
      viewport.setAttribute('content', 'user-scalable=0, viewport-fit=cover');
    } else {
      const viewport = document.getElementsByName('viewport')[0];
      viewport.setAttribute('content', 'initial-scale=1, width=device-width, viewport-fit=cover');
    }
  }, [mrzScanCamera]);

  useEffect(() => {
    if (noMatchFound) {
      handleApisForm();
      setNoMatchFound(false);
    }
    if (greencard) {
      handleApisForm();
      setGreencard(false);
    }
  }, [selectedPassenger]);

  // Error
  useEffect(() => {
    if (checkinFlightError) {
      setApisCheckinLoading(false);
      setShowError(true);
      if (checkinFlightError.some((error) => error.code === '17688' || error.code === '17652')) {
        setGreencard(true);
        setShowError(false);
      }
    }
  }, [checkinFlightError]);

  useEffect(() => {
    if (updateApisError) {
      setUpdateApisLoading(false);
      setShowError(true);
    }
    if (updateApisError?.code === '14740') {
      setDestinationActive(true);
    }
  }, [updateApisError]);

  // Show error message
  let errorMessage;
  if (showError && updateApisError) {
    errorMessage = (
      <Notification onClose={clearErrors} severity="error" message={updateApisError.message} />
    );
  }

  const isAnyPaxInChecked = booking && (booking.allJourneys
    .find((journeys) => (journeys[0].status === 'Open') && journeys.filter((flight) => flight && (flight.statuses.acceptance === 'Open') && flight.passengers
      .find((passenger) => (passenger.checkin))).length));

  return (
    <>
      <DialogComponent open={showInfoDialog} setOpen={setShowInfoDialog} action={null}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={2} className={classes.closeButton}>
            <IconButton
              onClick={() => setShowInfoDialog(false)}
              className={classes.buttonClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <AcceptanceHelpInfo />
      </DialogComponent>
      <DialogComponent open={selectWarningText} setOpen={setSelectWarningText} action={null}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={2} className={classes.closeButton}>
            <IconButton
              onClick={() => setSelectWarningText(false)}
              className={classes.buttonClose}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Typography variant="h1" className={classes.selectWarning}><Text id="select-passenger-warning" /></Typography>
      </DialogComponent>
      <DialogComponent open={noMatchFound} setOpen={setNoMatchFound} action={null}>
        <>
          <Typography variant="h1" className={classes.selectWarning}><Text id="scan-issue-select-passenger-text" /></Typography>
          {passengers?.length > 0
              && passengers.map((passenger, index) => {
                if (!passenger.acceptance.apiDataRegistered) {
                  return (
                    <ButtonComponent
                      className={classes.button}
                      fullWidth
                      color="secondary"
                      variant="contained"
                      onClick={() => setSelectedPassenger(index)}
                    >
                      {passenger.name.fullName}
                    </ButtonComponent>
                  );
                }
                return null;
              })}
        </>
      </DialogComponent>
      <DialogComponent open={greencard} setOpen={setGreencard} action={null}>
        <>
          <Typography variant="h1" className={classes.selectWarning}><Text id="travel-document-required-text" /></Typography>
          {passengers?.length > 0
              && passengers.map((passenger, index) => (
                <ButtonComponent
                  className={classes.button}
                  fullWidth
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    setGreencardActive(true);
                    setSelectedPassenger(index);
                  }}
                >
                  {passenger.name.fullName}
                </ButtonComponent>
              ))}
        </>
      </DialogComponent>
      <Dialog
        fullScreen
        open={open}
        setOpen={setOpen}
        action={null}
      >
        <Header />
        <Grid container className={classes.container}>
          <Grid container className={classes.mainTestContainer} alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h1" className={classes.scanTitle}><Text id="scan-passport-fullscreenModal-title" /></Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={() => setShowInfoDialog(true)}>
                <InfoIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h1" className={classes.completetCount}>
              {passengers.filter((passenger) => passenger.acceptance.apiDataRegistered).length === passengers.length ? <Text id="all-passenger-apis-updated-text" /> : `${passengers.filter((passenger) => passenger.acceptance.apiDataRegistered).length} of ${passengers.length}`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} className={classes.buttonContainer}>
            <Grid container>
              <Grid item xs={6} md={12}>
                <Box className={passengers.every((passenger) => passenger.acceptance.apiDataRegistered) ? classes.disabled : classes.imageBox} onClick={() => handleCameraScan(true)}>
                  <CameraIcon />
                  <Typography variant="h1" className={classes.scanText}><Text id="select-scan-camera-text" /></Typography>
                </Box>
              </Grid>
              {!isXpScreen && (
                <Grid item xs={12} md={12}>
                  <Divider className={classes.divider} orientation="horizontal" flexItem>
                    <Text id="or-apis" />
                  </Divider>
                </Grid>
              )}
              <Grid item xs={6} md={12}>
                <Box className={selectedPassenger === null || passengers.every((passenger) => passenger.acceptance.apiDataRegistered) ? classes.disabled : classes.imageBox} onClick={() => handleApisForm()}>
                  <FormIcon />
                  <Typography variant="h1" className={classes.scanText}><Text id="select-scan-manually-text" /></Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Box m={2}>
                  <SelectedPassenger
                    selectedPassenger={selectedPassenger}
                    setSelectedPassenger={setSelectedPassenger}
                    passengers={passengers}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {errorMessage}
          <DialogContent>
            <Dialog
              fullScreen
              open={mrzScanCamera}
              setOpen={setMrzScanCamera}
              action={null}
            >
              <div style={{
                position: 'absolute',
                top: '0px',
                left: '0px',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
              }}
              >
                <MrzScanner
                  hideContent
                  hideCheck
                  hideProgress
                  hideButton
                  scanResult={mrzScanResult}
                  fullscreen
                  scanClose={scanClose}
                  viewport={startDocumentScan ? viewportFor3LinesMrz : viewport}
                  iconClose={(
                    <CloseIcon
                      className={classes.icon}
                      style={{
                        fontSize: '6vh',
                        verticalAlign: 'bottom',
                      }}
                    />
                    )}
                />
                <Box className={classes.passportImage}>
                  <img className={classes.scanImage} src={`${wwwDir}${Passport}`} alt="logo" />
                </Box>
              </div>
            </Dialog>
            {apisForm && selectedPassenger !== null && !mrzScanCamera ? (
              <Box style={{ zIndex: 1 }}>
                <AcceptanceForm
                  booking={flight}
                  value={value}
                  setValue={setValue}
                  activePassenger={selectedPassenger}
                  passengers={passengers}
                  station={flight.station}
                  open={apisForm}
                  setOpen={setApisForm}
                  updateAcceptance={handleUpdateAcceptance}
                  setMrzScanCamera={setMrzScanCamera}
                  loadingBar={updateApisLoading}
                  setUpdateApisLoading={setUpdateApisLoading}
                  ocrScanned={ocrScanned}
                  setOcrScanned={setOcrScanned}
                  showConditionsModal={showConditionsModal}
                  setShowConditionsModal={setShowConditionsModal}
                  updateApisError={updateApisError}
                  clearErrors={clearErrors}
                  setStartDocumentScan={setStartDocumentScan}
                  startDocumentScan={startDocumentScan}
                  greencardActive={greencardActive}
                  setGreencardActive={setGreencardActive}
                  destinationActive={destinationActive}
                  passengerAcceptance={passengers.map((passenger) => ({
                    name: passenger.name.fullName,
                    warnings: [
                      { done: passenger.acceptance.apiDataRegistered ? passenger.acceptance.apiDataRegistered : false, type: 'API' },
                      { done: passenger.acceptance.adcConfirmed ? passenger.acceptance.adcConfirmed : false, type: 'ADC' },
                    ],
                  }))}
                />
              </Box>
            ) : null}
          </DialogContent>
          <Grid item xs={12}>
            {apisCheckinLoading && (
            <Box className={classes.root}>
              <LinearProgress />
            </Box>
            )}
            <DialogActions>
              <Box m={1} style={{ width: '100%' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      className={classes.actionCheckInButtons}
                      fullWidth
                      color="secondary"
                      action={() => handelCheckin()}
                      text={flight.passengers.every((passenger) => passenger.checkin) ? <Typography color="textSecondary" variant="caption"><Text id="flight-card-header-all-checked-in" /></Typography>
                        : <Typography color="textSecondary" variant="caption"><Text id="activeJourney-passenger-card-action-checkin" /></Typography>}
                      disabled={passengers.some((passenger) => !passenger.acceptance.apiDataRegistered === true) || flight.passengers.every((passenger) => passenger.checkin)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      text={<Typography variant="h4"><Text id="footer-back-button" /></Typography>}
                      action={() => {
                        setApisForm(false);
                        setSelectedPassenger(null);
                        setOpen(false);
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      className={classes.actionCheckInButtons}
                      fullWidth
                      color="secondary"
                      action={() => history.push(routes.SUMMARY)}
                      text={<Typography color="textSecondary" variant="caption"><Text id="footer-continue-button" /></Typography>}
                      disabled={!isAnyPaxInChecked}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default AcceptanceModal;
