import config from '../../../config';

const initialState = {
  features: {
    boardingpasses: {
      onlyBoardingpass: true,
      boardingpassesButtons: false,
    },
  },
};

const reducer = (state = initialState) => {
  const configuration = config.get(process.env.REACT_APP_STAGE);
  switch (configuration.ENVIRONMENT) {
    case ('WGH'):
      return {
        ...state,
        features: {
          boardingpasses: {
            onlyBoardingpass: false,
            boardingpassesButtons: false,
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
