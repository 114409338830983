/* eslint-disable max-len */
import React, { } from 'react';
import { Typography, Box } from '@material-ui/core';

import Accordion from '../../components/Legal/Accordion';
import FadeInContainer from '../../components/UI/FadeInContainer/FadeInContainer';
import PageTitle from '../../components/UI/PageTitle/PageTitle';

const PrivacyPolicy = () => (
  <FadeInContainer maxWidth="sm">
    <Box mt={{ xs: 0, sm: 8 }} mb={10} style={{ paddingBottom: 120 }}>
      <PageTitle>
        <Box>
          Privacy Policy of Widerøe Ground Handling
        </Box>
      </PageTitle>
      <Typography variant="body2" gutterBottom>
        <b>
          <Box>
            Updated February 8, 2023
          </Box>
          <br />
          <br />
          <Box>
            Widerøe Ground Handling’s (“WGH”) Privacy Policy
            describes how WGH collects, uses, and shares your personal data.
          </Box>
          <br />
          <br />
          <Box>
            Please take a moment to familiarize yourself with our privacy practices, accessible via the headings below, and contact us if you have any questions.
          </Box>
          <br />
        </b>
      </Typography>

      <Accordion title={<Box>What Is Personal Data at WGH?</Box>}>
        <Typography variant="body2" gutterBottom>
          <Box>
            At WGH, we believe strongly in fundamental privacy rights — and that those fundamental rights should not differ depending on where you live in the world. That’s why we treat any data that relates to an identified or identifiable individual or that is linked or linkable to them by WGH as “personal data,” no matter where the individual lives. This means that data that directly identifies you — such as your name — is personal data, and also data that does not directly identify you, but that can reasonably be used to identify you — such as your airline/travel agent booking reference — is personal data.
          </Box>
          <br />
          <br />
          <Box>
            This Privacy Policy covers how WGH handles personal data whether you interact with us on our websites, through WGH apps (such as Self-service Check-in or Pay-by-link), or in person (including by phone, e-mail or when visiting us at our airports).
          </Box>
        </Typography>

      </Accordion>
      <Accordion title={<Box>Your Privacy Rights at WGH</Box>}>
        <Typography variant="body2" gutterBottom>
          <Box>
            At WGH, we respect your ability to know, access, correct, transfer, restrict the processing of, and delete your personal data. If you choose to exercise these privacy rights, you have the right not to be treated in a discriminatory way nor to receive a lesser degree of service from WGH. WGH does not sell your data.
          </Box>
          <br />
          <br />
          <Box>
            To exercise your privacy rights and choices, please contact us by e-mail to
          </Box>

          <b>
            <Box> personvern@wideroe.no</Box>
          </b>
          <br />
          <br />
          <Box>
            There may be situations where we cannot grant your request — for example, if you ask us to delete your transaction data and WGH is legally obligated to keep a record of that transaction to comply with law. We may also decline to grant a request where doing so would undermine our legitimate use of data for anti-fraud and security purposes. Other reasons your privacy request may be denied are if it jeopardizes the privacy of others, is frivolous or vexatious, or would be extremely impractical.
          </Box>
        </Typography>
      </Accordion>

      <Accordion title={<Box>Personal Data WGH Collects from You</Box>}>
        <Box>
          <Typography variant="body2" gutterBottom>
            <Box>
              At WGH, we believe that you can have great travel experience and great privacy. This means that we strive to collect only the personal data that we need. The personal data WGH collects depends on how you interact with WGH.
            </Box>
            <br />
            <br />
            <Box>
              When you s a product and services, connect to our services, contact us (including by social media), participate in an online survey, or otherwise interact with WGH, we may collect a variety of information, including:
            </Box>
            <br />
          </Typography>
          <Box variant="body2">
            <ul>
              <li>
                <b><Box>Travel Information.</Box></b>
                {' '}
                <Box>Your travel details, such as date of birth, gender, booking reference, ticket number, bag tag number, passport number or other travel information</Box>
              </li>
              <li>
                <b><Box>Device Information.</Box></b>
                {' '}
                <Box>Data from which your device could be identified, such as web browser type, or about your device</Box>
              </li>
              <li>
                <b><Box>Contact Information.</Box></b>
                {' '}
                <Box>Data such as name, email address, physical address, phone number, or other contact information</Box>
              </li>
              <li>
                <b><Box>Payment Information.</Box></b>
                {' '}
                <Box>Data about your billing address and method of payment, such as bank details, credit, debit, or other payment card information</Box>
              </li>
              <li>
                <b><Box>Transaction Information.</Box></b>
                {' '}
                <Box>Data about purchases of WGH products and services or transactions facilitated by WGH, including purchases on WGH platforms</Box>
              </li>
              <li>
                <b><Box>Fraud Prevention Information.</Box></b>
                {' '}
                <Box>Data used to help identify and prevent fraud, including a device trust score</Box>
              </li>
              <li>
                <b><Box>Usage Data.</Box></b>
                {' '}
                <Box>Data about your activity on and use of our products and services, such as app launches within our services, including browsing history; search history; product interaction; crash data, performance and other diagnostic data; and other usage data</Box>
              </li>
              <li>
                <b><Box>Location Information.</Box></b>
                {' '}
                <Box>Precise location only to support Mishandled baggage (bags.wgh.no), and coarse location</Box>
              </li>
              <li>
                <b><Box>Other Information You Provide to Us.</Box></b>
                {' '}
                <Box>Details such as the content of your communications with WGH, including interactions with customer support and contacts through social media channels</Box>
              </li>
            </ul>
          </Box>
          <Typography variant="body2" gutterBottom>
            <Box>You are not required to provide the personal data that we have requested. However, if you choose not to do so, in many cases we will not be able to provide you with our products or services or respond to requests you may have.</Box>
          </Typography>
        </Box>

      </Accordion>

      <Accordion title={<Box>Personal Data WGH Receives from Other Sources</Box>}>
        <Box>
          <Typography variant="body2" gutterBottom>
            <Box>WGH may receive personal data about you from other individuals, from businesses or third parties acting at your direction, from our partners who work with us to provide our products and services and assist us in security and fraud prevention, and from other lawful sources.</Box>
          </Typography>
          <Box variant="body2">
            <ul>
              <li>
                <b><Box>Individuals.</Box></b>
                {' '}
                <Box>WGH may collect data about you from other individuals — for example, if that individual has organized your travel or a group of passengers traveling together.</Box>
              </li>
              <li>
                <b><Box>At Your Direction.</Box></b>
                {' '}
                <Box>You may direct other individuals or third parties to share data with WGH. For example, when you purchase a travel from your airline/travel agent, you give your consent that your data may be passed on to vicarious agents, e.g. service providers for ground handling services (such as WGH) and other additional related services, to make sure your baggage arrives at destination or handle any issues connected with your trip.</Box>
              </li>
              <li>
                <b><Box>WGH Partners.</Box></b>
                {' '}
                <Box>We may also validate the information you provide — for example, we transmit data to the extent that we are obliged to participate in international travel control activities or for security, and for fraud-prevention purposes.</Box>
              </li>
            </ul>
          </Box>
        </Box>
      </Accordion>

      <Accordion title={<Box>WGH’s Use of Personal Data</Box>}>
        <Box>
          <Typography variant="body2" gutterBottom>
            <Box>WGH uses personal data to power our services, to process your transactions, to communicate with you, for security and fraud prevention, and to comply with law. We may also use personal data for other purposes with your consent.</Box>
            <br />
            <br />
            <Box>WGH uses your personal data only when we have a valid legal basis to do so. Depending on the circumstance, WGH may rely on your consent or the fact that the processing is necessary to fulfill a contract with you, protect your vital interests or those of other persons, or to comply with law. We may also process your personal data where we believe it is in our or other’s legitimate interests, taking into consideration your interests, rights, and expectations. If you have questions about the legal basis, you can contact the Data Protection Officer by e-mail to</Box>
            {' '}
            <b><Box>personvern@wideroe.no</Box></b>
          </Typography>
          <Box variant="body2">
            <ul>
              <li>
                <b><Box>Power Our Services.</Box></b>
                {' '}
                <Box>WGH collects personal data necessary to power our services, which may include personal data collected to personalize or improve our services, for internal purposes such as auditing or data analysis, or for troubleshooting. For example, if you would like to report, track and managing your delayed or damaged baggage, we collect data on what baggage that is mishandled in order to locate your baggage, and advise you once your baggage has been received and arrange a convenient time for delivery.</Box>
              </li>
              <li>
                <b><Box>Process Your Transactions.</Box></b>
                {' '}
                <Box>To process transactions, WGH must collect data such as your name, purchase, and payment information.</Box>
              </li>
              <li>
                <b><Box>Communicate with You.</Box></b>
                {' '}
                <Box>To respond to communications, reach out to you about your transactions, market our products and services, provide other relevant information, or request information or feedback. From time to time, we may use your personal data to send important notices, such as communications about purchases and changes to our terms, conditions, and policies. Because this information is important to your interaction with WGH, you may not opt out of receiving these important notices.</Box>
              </li>
              <li>
                <b><Box>Security and Fraud Prevention.</Box></b>
                {' '}
                <Box>To protect individuals, employees, and WGH and for loss prevention and to prevent fraud, including to protect individuals, employees, and WGH for the benefit of all our users, and prescreening or scanning uploaded content for potentially illegal content, including child sexual exploitation material.</Box>
              </li>
              <li>
                <b><Box>Comply with Law.</Box></b>
                {' '}
                <Box>To comply with applicable law — for example, to satisfy tax or reporting obligations, or to comply with a lawful governmental request.</Box>
              </li>
            </ul>
          </Box>
          <Typography variant="body2" gutterBottom>
            <Box>WGH does not use algorithms or profiling to make any decision that would significantly affect you without the opportunity for human review.</Box>
            <br />
            <br />
            <Box>WGH retains personal data only for so long as necessary to fulfill the purposes for which it was collected, including as described in this Privacy Policy or in our service-specific privacy notices, or as required by law. We will retain your personal data for the period necessary to fulfill the purposes outlined in this Privacy Policy and our service-specific privacy summaries. When assessing retention periods, we first carefully examine whether it is necessary to retain the personal data collected and, if retention is required, work to retain the personal data for the shortest possible period permissible under law.</Box>
          </Typography>
        </Box>
      </Accordion>

      <Accordion title={<Box>WGH’s Sharing of Personal Data</Box>}>
        <Box>
          <Typography variant="body2" gutterBottom>
            <Box>WGH may share personal data with service providers who act on our behalf, our partners, or others at your direction. Further, WGH does not share personal data with third parties for their own marketing purposes.</Box>
          </Typography>
          <Box variant="body2">
            <ul>
              <li>
                <b><Box>Service Providers.</Box></b>
                {' '}
                <Box>WGH may engage third parties to act as our service providers and perform certain tasks on our behalf, such as delivering products and services to you. WGH service providers are obligated to handle personal data consistent with this Privacy Policy and according to our instructions. They cannot use the personal data we share for their own purposes and must delete or return the personal data once they have fulfilled our request.</Box>
              </li>
              <li>
                <b><Box>Partners.</Box></b>
                {' '}
                <Box>At times, WGH may partner with third parties to provide services or other offerings. For example, airport security fast track or airport lounge offerings offered by WGH and our partners. WGH requires its partners to protect your personal data.</Box>
              </li>
              <li>
                <b><Box>Others.</Box></b>
                {' '}
                <Box>WGH may share personal data with others at your direction or with your consent, such as when we share information with immigration authorities to the extent that we are obliged to participate in international travel control activities. We may also disclose information about you if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate. We may also disclose information about you where there is a lawful basis for doing so, if we determine that disclosure is reasonably necessary to enforce our terms and conditions or to protect our operations or users, or in the event of a reorganization, merger, or sale.</Box>
              </li>
            </ul>
          </Box>
        </Box>
      </Accordion>

      <Accordion title={<Box>Protection of Personal Data at WGH</Box>}>
        <Typography variant="body2" gutterBottom>
          <Box>At WGH, we believe that great privacy rests on great security. We use administrative, technical, and physical safeguards to protect your personal data, taking into account the nature of the personal data and the processing, and the threats posed. We are constantly working to improve on these safeguards to help keep your personal data secure.</Box>
        </Typography>
      </Accordion>

      <Accordion title={<Box>Cookies and Other Technologies</Box>}>
        <Box>
          <Typography variant="body2" gutterBottom>
            <Box>WGH’s websites, online services, interactive applications, email messages, and advertisements may use “cookies” and other technologies such as pixel tags and web beacons. These technologies help us to better understand user behavior including for security and fraud prevention purposes, tell us which parts of our websites people have visited, and facilitate and measure the effectiveness of advertisements and web searches.</Box>
          </Typography>
          <Box variant="body2">
            <ul>
              <li>
                <b><Box>Communications Cookies.</Box></b>
                {' '}
                <Box>These cookies are used to enable network traffic to and from WGH’s systems, including by helping us detect any errors.</Box>
              </li>
              <li>
                <b><Box>Strictly Necessary Cookies.</Box></b>
                {' '}
                <Box>These cookies are set as required to provide a specific feature or service that you have accessed or requested. For example, they allow us to display our websites in the proper format and language, to authenticate and verify your transactions, and to preserve the contents of your Bag when shopping online at wgh.no.</Box>
              </li>
              <li>
                <b><Box>Other Cookies.</Box></b>
                {' '}
                <Box>These cookies are used to understand how visitors interact with our websites and online services, including by helping us to assess the effectiveness of advertisements and web searches. WGH also uses these cookies to remember choices you make while browsing, so we can provide you with a customized experience.</Box>
              </li>
            </ul>
          </Box>
          <Typography variant="body2" gutterBottom>

            <Box>If you prefer that WGH not use cookies, we provide you with the means to disable their use. If you want to disable cookies check with your web browser provider to find out how to disable cookies. Certain features of the WGH website may not be available if all cookies are disabled.</Box>
            <br />
            <br />
            <Box>In addition to cookies, WGH uses other technologies that help us achieve similar objectives.</Box>
            <br />
            <br />
            <Box>In some email messages WGH sends to you, we provide a “click-through URL” that links you to content on the WGH website. When you click one of these URLs, they pass through a separate server before arriving at the destination page on our website. We track this click-through to help us determine interest in particular topics and measure whether we are communicating with you effectively. If you prefer not to be tracked in this way, you should not click graphic or text links in email messages.</Box>
            <br />
            <br />
            <Box>Pixel tags enable us to send email messages in a format that you can read, and they tell us whether mail has been opened. We may use this data to reduce or eliminate messages sent to customers.</Box>
            <br />
            <br />
            <Box>WGH generally treats data we collect using these cookies and similar technologies as nonpersonal data. However, to the extent that Internet Protocol (IP) addresses or similar identifiers are considered personal data by local law, we also treat these identifiers as personal data. In addition, WGH sometimes combines nonpersonal data collected from these technologies with other personal data WGH holds. When we combine data in this way, we treat the combined data as personal data for purposes of this Privacy Policy.</Box>
          </Typography>
        </Box>
      </Accordion>

      <Accordion title={<Box>Transfer of Personal Data Between Countries</Box>}>
        <Typography variant="body2" gutterBottom>
          <Box>WGH products and services connect you to the world. To make that possible, your personal data may be transferred to or accessed by entities around the world. WGH complies with laws on the transfer of personal data between countries to help ensure your data is protected, wherever it may be.</Box>
        </Typography>
      </Accordion>

      <Accordion title={<Box>Our Companywide Commitment to Your Privacy</Box>}>
        <Typography variant="body2" gutterBottom>
          <Box>To make sure your personal data is secure, we communicate our privacy and security guidelines to WGH employees and strictly enforce privacy safeguards within the company.</Box>
        </Typography>
      </Accordion>

      <Accordion title={<Box>Privacy Questions</Box>}>
        <Typography variant="body2" gutterBottom>
          <Box>If you have questions about WGH’s Privacy Policy or privacy practices, would like to contact our Data Protection Officer, or would like to submit a complaint, you can contact us by e-mail to</Box>
          {' '}
          <b><Box>personvern@wideroe.no</Box></b>
          <Box>. You can also ask us questions about how to submit a privacy complaint and we will endeavor to help.</Box>
          <br />
          <br />
          <Box>WGH takes your privacy questions seriously. A dedicated team reviews your inquiry to determine how best to respond to your question or concern, including those inquiries received in response to an access or download request. In most cases, all substantive contacts receive a response within seven days. In other cases, we may require additional information or let you know that we need more time to respond.</Box>
          <br />
          <br />
          <Box>Where your complaint indicates an improvement could be made in our handling of privacy issues, we will take steps to make such an update at the next reasonable opportunity. In the event that a privacy issue has resulted in a negative impact on you or another person, we will take steps to address that with you or that other person.</Box>
          <br />
          <br />
          <Box>If you are not satisfied with WGH’s response, you may refer your complaint to the applicable regulator. If you ask us, we will endeavor to provide you with information about relevant complaint avenues which may be applicable to your circumstances.</Box>
          <br />
          <br />
          <Box>When there is a material change to this Privacy Policy, we’ll post a notice on this website at least a week in advance of doing so and contact you directly about the change if we have your data on file.</Box>
          <br />
          <br />
          <Box>Widerøe Ground Handling AS, Langstranda 6, 8003 Bodø, Norway</Box>
        </Typography>
      </Accordion>

    </Box>
  </FadeInContainer>
);

export default PrivacyPolicy;
